import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";

const StyledButton = styled(Button)`
  --tw-text-opacity: 1;
  color: #000;
  text-transform: none;
  :hover {
    --tw-text-opacity: 1;
    color: #707070 !important;
    --tw-bg-opacity: 1;
    background-color: #f0f0f0;
  }
`;

const StyledLink = styled("a")`
  color: #d2cecc;
  width: 100%;
  :hover {
    color: #707070 !important;
    --tw-bg-opacity: 1;
    background-color: #f0f0f0;
  }
`;

const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "#F0F0F0",
  },
}));

const DropdownButton = ({ item }) => {
  useEffect(() => {
    if (
      path === "/biblioteca/BSA" ||
      path === "/biblioteca/BSD" ||
      path === "/biblioteca/BCH" ||
      path === "/biblioteca/ITTA" ||
      path === "/biblioteca/ITLA" ||
      path === "/biblioteca/ITCH" ||
      path === "/biblioteca/ITCA" ||
      path === "/direccion"
    ) {
      const element = document.getElementById("Bibliotecas");
      element.classList.add("active");
    } else if (
      path === "/inv/apoyo" ||
      path === "/inv/publicacion" ||
      path === "/inv/visibilidad" ||
      path === "/inv/enlaces"
    ) {
      const element = document.getElementById("Apoyo a la Investigación");
      element.classList.add("active");
    } else if (
      path === "/ayuda" ||
      path === "/ayuda-digital" ||
      path === "/consultas"
    ) {
      const element = document.getElementById("Ayuda");
      element.classList.add("active");
    } else if (path === "/stalleres" || path === "/sarticulos") {
      const element = document.getElementById("Solicitudes");
      element.classList.add("active");
    }
    return () => {};
  });

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const path = window.location.pathname;

  const handleSelect = (el) => {
    if (path === el.href) {
      return true;
    }
  };

  return (
    <div>
      <StyledButton
        id={item.name}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="cursor-pointer navSubMenu text-[#646567] !hover:bg-white-700 !hover:text-white px-3 py-2 rounded-md !text-sm !font-bold d-flex"
      >
        {item.name}
      </StyledButton>
      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {item.subList.map((el, i) => (
          <MenuItem
            key={i}
            onClick={handleClose}
            selected={handleSelect(el)}
            className="p-0"
          >
            {/* color barra submenu desplegable */}
            <StyledLink
              href={el.href}
              className="px-2 py-1 sizeMenuDesp font-bold !text-[#646567]"
            >
              {el.name}
            </StyledLink>
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
};
export default DropdownButton;

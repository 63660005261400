import React, { useState, useRef, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "../../css/Request.css";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Swal from "sweetalert2";
import ChileanRutify from "chilean-rutify";
import Banner from "../../assets/images/banner/banner_request_var.png";
import Select from "react-select";
import ReCAPTCHA from "react-google-recaptcha";
import moment from "moment";
import {
  CCol,
  CFormInput,
  CFormTextarea,
  CRow,
  CForm,
  CFormLabel,
  CFormSelect,
} from "@coreui/react";

const Bibliotecary = ({ selectSede }) => {
  const [hidden, setHidden] = useState(true);
  const [email, setEmail] = useState();
  const [rut, setRut] = useState();
  const [consulta, setConsulta] = useState();
  const [ua, setUA] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const [carreras, setCarreras] = useState([]);
  const [facultades, setFacultades] = useState([]);
  const recaptcha = useRef();
  const fecha = moment().format("YYYY-MM-DD");

  const sedes = [
    { value: "Cañete", label: "Cañete" },
    { value: "Chillán", label: "Chillán" },
    { value: "Los Ángeles", label: "Los Ángeles" },
    { value: "Talcahuano", label: "Talcahuano" },
  ];

  const handleChange = (value) => {
    setUA(value.value);
    setSelectedOption(value);
  };

  const changeSede = async (e) => {
    setSelectedOption(null);
    if (e.value != "") {
      const response = await axios
        .get(
          "https://sibucsc.cl/sibucsc/carreras/?facultad=Instituto+Tecnol%C3%B3gico&sede=" +
            e.value,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "ip-address": "10.1.0.4",
            },
          }
        )
        .then((response) => {
          const carreraData = response.data.results.map((item) => ({
            label: item.nombre + " " + "- " + item.jornada,
            value: item.ua,
          }));
          setCarreras(carreraData);
        })
        .catch((err) => {});
    } else {
      setCarreras([]);
    }
  };

  const getApiDataFacultades = async () => {
    const response = await axios
      .get("https://sibucsc.cl/sibucsc/carreras/facultades/", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "ip-address": "10.1.0.4",
        },
      })
      .then((response) => {
        setFacultades(response.data);
      })
      .catch((err) => {});
  };

  const changeCarrera = async (e) => {
    setSelectedOption(null);
    if (e.target.value != "") {
      if (e.target.value === "Instituto Tecnológico") {
        setHidden(false);
        setCarreras([]);
      } else {
        setHidden(true);
        const response = await axios
          .get(
            "https://sibucsc.cl/sibucsc/carreras/?facultad=" + e.target.value,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "ip-address": "10.1.0.4",
              },
            }
          )
          .then((response) => {
            const carreraData = response.data.results.map((item) => ({
              label: item.nombre + " " + "- " + item.jornada,
              value: item.ua,
            }));
            setCarreras(carreraData);
          })
          .catch((err) => {});
      }
    } else {
      setCarreras([]);
      setHidden(true);
    }
  };

  useEffect(() => {
    getApiDataFacultades();
  }, []);

  const handleSubmit = async (e) => {
    // Prevent the default submit and page reload
    e.preventDefault();
    const captchaValue = recaptcha.current.getValue();

    if (captchaValue) {
      if (ChileanRutify.validRut(rut) === true) {
        Swal.fire({
          title: "Enviando consulta",
          html: "Espere por favor",
          didOpen: () => {
            Swal.showLoading();
          },
        });
        // Handle validations
        try {
          const loginResponse = await fetch(
            "https://sibucsc.cl/sibucsc/roundtable/token/",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                username: "gallahad",
                password: "f0rms-lancel0t",
              }),
            }
          );

          if (!loginResponse.ok) {
            throw new Error("Login failed");
          }

          const data = await loginResponse.json();

          const response = await axios.post(
            "https://sibucsc.cl/sibucsc/consultas/",
            { rut, email, ua, consulta, fecha },
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + data.access,
              },
            }
          );
          if (response.status === 201) {
            Swal.fire(
              "Solicitud enviada con éxito",
              "presione OK para terminar",
              "success"
            ).then(() => {
              window.location.reload();
            });
          }
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Error al enviar la solicitud, intente nuevamente",
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Rut incorrecto, intente nuevamente",
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Por favor verifica reCAPTCHA!",
      });
    }
  };

  return (
    <>
      <Container fluid className="web-container pt-3" id="bannerSIBUCSC">
        <Row className="col d-flex justify-content-center">
          <Col xl={4}>
            <div className="col d-flex justify-content-center">
              <img src={Banner} id="imgBanner"></img>
            </div>
          </Col>
          <Col xl={6} className="mt-md-5">
            <div>
              <div className="m-3">
                <h3 className="main-heading text-white">
                  Consulta a un Bibliotecario
                </h3>
                <h6 className="text-justify text-[#C9CCD2]">
                  Consulta y plantea tus inquietudes o dudas, las cuales serán
                  atendidas y respondidas por nuestro equipo. Cabe destacar que
                  contamos con 5 días hábiles para dar respuesta a tu solicitud.
                </h6>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div>
        <Container fluid className="web-container">
          <Row className="col d-flex justify-content-center">
            <Col xl={12} sm={12} md={12} xs={12}></Col>
          </Row>
          <Row className="col d-flex justify-content-center m-4">
            <Col xl={6} sm={12} md={12} xs={12}>
              <CForm
                className="border p-3"
                action=""
                method="post"
                onSubmit={handleSubmit}
              >
                <h5 className="text-[#d12421] font-bold">
                  Formulario de solicitud
                  <hr></hr>
                </h5>
                <Row>
                  <Col xl={12} sm={12} md={12} xs={12}>
                    <CRow className="mb-3 ">
                      {/* INPUT RUT */}
                      <CFormLabel
                        htmlFor="rut"
                        className="col-sm-3 col-form-label"
                      >
                        Rut
                      </CFormLabel>
                      <CCol sm={9}>
                        <CFormInput
                          type="text"
                          id="rut"
                          maxLength={9}
                          required
                          value={rut}
                          placeholder="RUT sin puntos ni guión, Ej: 12345678K"
                          onChange={(e) => setRut(e.target.value)}
                        />
                      </CCol>
                      {/* CIERRE INPUT RUT */}
                    </CRow>
                    <CRow className="mb-3 ">
                      {/* INPUT FACULTAD */}
                      <CFormLabel
                        htmlFor="rut"
                        className="col-sm-3 col-form-label"
                      >
                        Facultad
                      </CFormLabel>
                      <CCol sm={9}>
                        <CFormSelect
                          required
                          onChange={(event) => {
                            changeCarrera(event);
                          }}
                        >
                          <option value="">Seleccione Facultad</option>
                          {Object.entries(facultades).map(([key, fac], i) => (
                            <option key={i} value={fac}>
                              {fac}
                            </option>
                          ))}
                        </CFormSelect>
                      </CCol>
                      {/* CIERRE INPUT FACULTAD */}
                    </CRow>
                    {hidden ? null : (
                      <CRow className="mb-3" style={hidden ? "hidden" : ""}>
                        {/* INPUT SEDE */}
                        <CFormLabel
                          htmlFor="rut"
                          className="col-sm-3 col-form-label"
                        >
                          Sede
                        </CFormLabel>
                        <CCol sm={9}>
                          <Select
                            options={sedes}
                            placeholder="Seleccione Sede"
                            onChange={(event) => {
                              changeSede(event);
                            }}
                          />
                        </CCol>
                        {/* CIERRE INPUT CARRERA */}
                      </CRow>
                    )}
                    <CRow className="mb-3 ">
                      {/* INPUT CARRERA */}
                      <CFormLabel
                        htmlFor="rut"
                        className="col-sm-3 col-form-label"
                      >
                        Carrera
                      </CFormLabel>
                      <CCol sm={9}>
                        <Select
                          required
                          options={carreras}
                          value={selectedOption}
                          onChange={handleChange}
                          placeholder="Seleccione Carrera"
                        />
                      </CCol>
                      {/* CIERRE INPUT CARRERA */}
                    </CRow>
                    <CRow className="mb-3 ">
                      {/* INPUT EMAIL */}
                      <CFormLabel
                        htmlFor="email"
                        className="col-sm-3 col-form-label"
                      >
                        Email
                      </CFormLabel>
                      <CCol sm={9}>
                        <CFormInput
                          type="email"
                          id="email"
                          value={email}
                          maxLength={250}
                          placeholder="Ingrese su email aquí"
                          required
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </CCol>
                      {/* CIERRE INPUT EMAIL */}
                    </CRow>
                    <CRow className="mb-3 ">
                      {/* INPUT COMENTARIOS */}
                      <CFormLabel
                        htmlFor="comentarios"
                        className="col-sm-3 col-form-label"
                      >
                        Consulta
                      </CFormLabel>
                      <CCol sm={9}>
                        <CFormTextarea
                          type="email"
                          id="comentarios"
                          required
                          placeholder="Deje su consulta aquí (máx 500 carácteres)"
                          maxLength={500}
                          value={consulta}
                          onChange={(e) => setConsulta(e.target.value)}
                        />
                      </CCol>
                      {/* CIERRE COMENTARIOS */}
                    </CRow>
                    <CRow>
                      <CCol>
                        <div className="col d-flex justify-content-center">
                          <ReCAPTCHA
                            sitekey={process.env.REACT_APP_SITE_KEY}
                            ref={recaptcha}
                          />
                        </div>
                      </CCol>
                    </CRow>
                    <div className="col d-flex justify-content-center">
                      <Button
                        type="submit"
                        variant="outline-danger"
                        className="btnAnnounc mt-5"
                      >
                        Enviar
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CForm>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default Bibliotecary;

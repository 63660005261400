import "../../css/LibraryDetail.css";
import React from "react";
import Contactos from "./Contact";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useParams } from "react-router-dom";
import Horarios from "./Schedule";
import { Carousel } from "react-bootstrap";
import NewsAndMap from "./NewsAndMap";

const Library = () => {
  const params = useParams();
  const dataBSA = [
    {
      nombre: "Biblioteca Campus San Andrés",
      descripcion:
        "BSA, está compuesta por un edificio de 3 pisos de aproximadamente 3.157m2, con amplias salas de estudio individual y grupal, diferentes colecciones de material bibliográfico (Teológica, literatura, revistas académicas y referencia), además, de Laboratorios de computación con acceso a internet para la búsqueda de información digital y el uso de herramientas ofimáticas. Cuenta con préstamo de notebook, Tablet y calculadoras científicas y juegos de mesa.",
      abreviatura: "BSA",
    },
  ];

  const dataBSD = [
    {
      nombre: "Biblioteca Campus Santo Domingo",
      descripcion:
        "Cuenta con una con una superficie de 745.71mts2, que se dividen en: 2 salas de estudio y estantería abierta. Así mismo La colección que concentra es, 97% especializada en el área de derecho, ciencias jurídicas y 3% en misceláneas. Esta biblioteca tiene a disposición de los alumnos y docentes recursos electrónicos especializados en el área del derecho. La biblioteca brinda un servicio a los alumnos de Licenciatura en Derecho y de los posgrados de Magíster de Derecho Penal, Derecho Privado, y Derecho Procesal de Familia. Dotada con equipos computacionales para uso de usuarios y puestos de trabajos para estudio grupal e individual y también equipo inclusivo. (PIE)",
      abreviatura: "BSD",
    },
  ];

  const dataBCH = [
    {
      nombre: "Biblioteca Campus Clínico Chillán",
      descripcion:
        "Esta biblioteca está ubicada al interior del Hospital Clínico Herminda Martín de Chillán, exactamente en el Campus Clínico de la Facultad de Medicina de la Universidad Católica de la Santísima Concepción, cuenta con una superficie de 61.41 m2 y es de modalidad estantería abierta. La colección es 100% especializada en el área de medicina. Así mismo está dotada de equipos notebooks para préstamos y uso en sala, mesas de trabajo para estudio grupal, instrumental médico como fonendoscopio, martillos de reflejo, otoscopios. Esta Biblioteca tiene a disposición de los alumnos y docentes recursos electrónicos especializados en el área de la salud y brinda servicio a los alumnos de medicina, enfermería, nutrición y las especialidades médicas que realicen sus internados en el Hospital Clínico Herminda Martín.",
      abreviatura: "BCH",
    },
  ];

  const dataITTA = [
    {
      nombre: "Biblioteca Sede Talcahuano",
      descripcion:
        "Cuenta con una infraestructura de 89 metros cuadrados aproximadamente y así mismo está dotada con equipos computacionales y puestos de trabajos para estudios grupal e individual. Tiene una colección de 2.100 ejemplares con 700 títulos aproximadamente. La biblioteca presta servicio en jornadas diurna y vespertina a los alumnos y docentes de las carreras técnicas, ingenierías de ejecución y programas de continuidad de estudios.",
      abreviatura: "ITTA",
    },
  ];

  const dataITCH = [
    {
      nombre: "Biblioteca Sede Chillán",
      descripcion:
        "Cuenta con una infraestructura de 135 metros cuadrados y así mismo está dotada con equipos computacionales y puestos de trabajos para estudios grupal e individual, cuenta también con equipo inclusivo (PIE). Tiene una colección de 3200 ejemplares con 1100 títulos aproximadamente. La biblioteca presta servicios en jornadas diurna y vespertina a los alumnos y docentes de las carreras técnicas, ingenierías de ejecución y programas de continuidad de estudios.",
      abreviatura: "ITCH",
    },
  ];

  const dataITCA = [
    {
      nombre: "Biblioteca Sede Cañete",
      descripcion:
        "Cuenta con una infraestructura de 100 metros cuadrados y así mismo está dotada con equipos computacionales y puestos de trabajos para estudios grupal e individual, cuenta también con equipo inclusivo (PIE). Tiene una colección de 3200 ejemplares con 1100 títulos aproximadamente. La biblioteca presta servicios en jornadas diurna y vespertina a los alumnos y docentes de las carreras técnicas, ingenierías de ejecución y programas de continuidad de estudios.",
      abreviatura: "ITCA",
    },
  ];

  const dataITLA = [
    {
      nombre: "Biblioteca Sede Los Ángeles",
      descripcion:
        "Cuenta con una infraestructura de 74 metros cuadrados y así mismo está dotada con equipos computacionales y puestos de trabajos para estudios grupal e individual, cuenta también con equipo inclusivo (PIE). Tiene una colección de 3700 ejemplares con 1500 títulos aproximadamente. La biblioteca presta servicios en jornadas diurna y vespertina a los alumnos y docentes de las carreras técnicas, ingenierías de ejecución y programas de continuidad de estudios. Nuestra biblioteca tiene a disposición a su vez, de talleres especiales relacionados con el fomento lector (kamishibai, libros artesanales, técnicas de pop up, entre otros).",
      abreviatura: "ITLA",
    },
  ];

  const imgLibrarys = [1, 2, 3, 4];

  return (
    <>
      <Container fluid className="web-container pt-3" id="bannerSIBUCSC">
        <Row className="col d-flex justify-content-center">
          <Col xl={4} md={12} xs={12}>
            <div className="col mt-3 d-flex justify-content-center">
              <Carousel
                style={{ maxHeight: 300, maxWidth: 550 }}
                className="mt-4 imageLibrary shadow-lg"
              >
                {imgLibrarys.map(function (object) {
                  return (
                    <Carousel.Item
                      key={object}
                      style={{ maxHeight: 300, maxWidth: "auto" }}
                    >
                      <img
                        className="d-block w-100"
                        src={require(`../../assets/images/library/${params.abreviatura}-${object}.png`)}
                        alt="First slide"
                        style={{ maxHeight: 300, maxWidth: "auto" }}
                      ></img>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </div>
          </Col>
          <Col xl={5} md={12} xs={12}>
            <div className="divDescLibrary">
              {(() => {
                if (params.abreviatura == "BSA") {
                  return dataBSA.map((dat, i) => (
                    <div key={i}>
                      <h3 className="d-inline main-heading text-[#fff]">
                        {dat.nombre}
                      </h3>
                      <p className="text-justify mt-2 text-[#C9CCD2]">
                        {dat.descripcion}
                      </p>
                    </div>
                  ));
                } else if (params.abreviatura == "BSD") {
                  return dataBSD.map((dat, i) => (
                    <div key={i}>
                      <h3 className="d-inline main-heading text-[#fff]">
                        {dat.nombre}
                      </h3>
                      <p className="text-justify mt-2 text-[#C9CCD2]">
                        {dat.descripcion}
                      </p>
                    </div>
                  ));
                } else if (params.abreviatura == "BCH") {
                  return dataBCH.map((dat, i) => (
                    <div key={i} className="mb-4">
                      <h3 className="d-inline main-heading text-[#fff]">
                        {dat.nombre}
                      </h3>
                      <p className="text-justify text-[#C9CCD2]">
                        {dat.descripcion}
                      </p>
                    </div>
                  ));
                } else if (params.abreviatura == "ITTA") {
                  return dataITTA.map((dat, i) => (
                    <div key={i}>
                      <h3 className="d-inline main-heading text-[#fff]">
                        {dat.nombre}
                      </h3>
                      <p className="text-justify mt-2 text-[#C9CCD2]">
                        {dat.descripcion}
                      </p>
                    </div>
                  ));
                } else if (params.abreviatura == "ITCH") {
                  return dataITCH.map((dat, i) => (
                    <div key={i}>
                      <h3 className="d-inline main-heading text-[#fff]">
                        {dat.nombre}
                      </h3>
                      <p className="text-justify mt-2 text-[#C9CCD2]">
                        {dat.descripcion}
                      </p>
                    </div>
                  ));
                } else if (params.abreviatura == "ITCA") {
                  return dataITCA.map((dat, i) => (
                    <div key={i}>
                      <h3 className="d-inline main-heading text-[#fff]">
                        {dat.nombre}
                      </h3>
                      <p className="text-justify mt-2 text-[#C9CCD2]">
                        {dat.descripcion}
                      </p>
                    </div>
                  ));
                } else if (params.abreviatura == "ITLA") {
                  return dataITLA.map((dat, i) => (
                    <div key={i}>
                      <h3 className="d-inline main-heading text-[#fff]">
                        {dat.nombre}
                      </h3>
                      <p className="text-justify mt-2 text-[#C9CCD2]">
                        {dat.descripcion}
                      </p>
                    </div>
                  ));
                }
              })()}
            </div>
          </Col>
          <div className="divSpaceLibrary"></div>
        </Row>
      </Container>
      <Container fluid className="web-container">
        <Row>
          <Col xxl={6} xl={12} lg={12} md={12} xs={12}>
            <Horarios />
          </Col>
          <Col xxl={6} xl={12} md={12} xs={12} className="mt-5">
            <Contactos />
          </Col>
        </Row>
        <Row>
          <Col>
            <NewsAndMap />
          </Col>
        </Row>
      </Container>
      <br></br>
    </>
  );
};
export default Library;

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FaQuestionCircle, FaArrowRight } from "react-icons/fa";
import "../../css/Help.css";
import Accordion from "react-bootstrap/Accordion";
import APA from "../../assets/docs/rules/taller-4-apa-7edición.pdf";
import Banner from "../../assets/images/banner/banner_request_var.png";

const Questions = () => {
  return (
    <>
      <Container fluid className="web-container pt-3" id="bannerSIBUCSC">
        <Row className="col d-flex justify-content-center">
          <Col xl={4}>
            <div className="col d-flex justify-content-center">
              <img src={Banner} id="imgBanner"></img>
            </div>
          </Col>
          <Col xl={6} className="mt-md-5">
            <div>
              <div className="m-3">
                <h3 className="main-heading text-white">
                  Ayuda - Preguntas Frecuentes
                </h3>
                <h6 className="text-justify text-[#C9CCD2]">
                  El Sistema de Bibliotecas pone a disposición de sus usuarios,
                  una variedad de servicios que van ligados a la mejora en su
                  desarrollo de competencias informacionales
                </h6>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="web-container">
        <Row className="m-3 justify-content-center">
          <Col xl={10}>
            <Accordion flush>
              {/*  <Accordion.Item eventKey="0">
                <Accordion.Header>
                  ¿Cómo puedo buscar libros impresos en las Bibliotecas de la
                  UCSC?
                </Accordion.Header>
                <Accordion.Body>
                  <div className="text-justify font-bold">
                    Para buscar un libro impreso dentro de las colecciones de
                    nuestras bibliotecas, te invitamos a seguir las
                    instrucciones del siguiente Video tutorial:{" "}
                    <a
                      href="https://youtu.be/97cvzRNRD4w?si=-XDkwsY2_ZaZ2SQ9"
                      className="enlace"
                      target="__blank"
                    >
                      ver aquí
                    </a>
                  </div>
                </Accordion.Body>
              </Accordion.Item> */}
              {/*  <Accordion.Item eventKey="1" className="mt-2">
                <Accordion.Header>¿Cómo renovar un libro? </Accordion.Header>
                <Accordion.Body>
                  <div className="text-justify font-bold">
                    Para renovar un libro te invitamos a seguir las
                    instrucciones del siguiente Video tutorial:{" "}
                    <a
                      href="https://youtu.be/Vlwq7Uoq4pY?si=H3IAQdtTFJi0hR9H"
                      className="enlace"
                      target="__blank"
                    >
                      ver aquí
                    </a>
                  </div>
                </Accordion.Body>
              </Accordion.Item> */}
              <Accordion.Item eventKey="2" className="mt-2">
                <Accordion.Header>
                  ¿Cómo solicitar un artículo de una publicación científica?{" "}
                </Accordion.Header>
                <Accordion.Body>
                  <div className="text-justify font-bold">
                    Para solicitar un artículo te dejamos el siguiente enlace{" "}
                    <a href="/sarticulos" className="enlace" target="__blank">
                      ver aquí
                    </a>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3" className="mt-2">
                <Accordion.Header>¿Cómo citar en APA?</Accordion.Header>
                <Accordion.Body>
                  {" "}
                  <div className="text-justify font-bold">
                    Para aprender a citar en formato APA te dejamos las
                    siguientes indicaciones
                  </div>
                  <div className="text-justify">
                    Puedes revisar nuestro manual haciendo clic
                    <a href={APA} className="enlace font-bold" target="__blank">
                      {" "}
                      acá
                    </a>{" "}
                    con lo más consultado de APA y si aún tienes dudas puedes
                    contactar a la jefatura de Biblioteca correspondiente a tu
                    sede.{" "}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              {/*  <Accordion.Item eventKey="4" className="mt-2">
                <Accordion.Header>
                  ¿Cómo ingresar a los recursos digitales o Bases de datos?
                </Accordion.Header>
                <Accordion.Body>
                  <div className="text-justify font-bold">
                    Para ingresar a recursos digitales o Bases de Datos. Te
                    invitamos a seguir las instrucciones del siguiente Video
                    tutorial :{" "}
                    <a
                      href="https://youtu.be/W0QCz0YCSGI?si=ghTLN4r-q04jbty5"
                      className="enlace"
                      target="__blank"
                    >
                      ver aquí
                    </a>
                  </div>
                </Accordion.Body>
              </Accordion.Item> */}
              <Accordion.Item eventKey="5" className="mt-2">
                <Accordion.Header>
                  ¿Cómo realizar un trámite administrativo?
                </Accordion.Header>
                <Accordion.Body>
                  <div className="text-justify font-bold">
                    Para cada uno de los trámites administrativos que se
                    presentan a continuación debes contactarte con la jefatura
                    de Biblioteca correspondiente a tu sede.
                  </div>
                  <div className="mt-3">
                    <b>
                      Si no tienes conocimiento de quien es la jefatura de
                      Biblioteca correspondiente a tu sede, te dejamos la
                      información:
                    </b>
                  </div>
                  <div>
                    <ul className="list-group list-group-flush">
                      <li>
                        <FaArrowRight className="inline" />
                        <a href="/biblioteca/BSA" className="enlace font-bold">
                          {" "}
                          San Andrés
                        </a>
                      </li>
                      <li>
                        <FaArrowRight className="inline" />
                        <a href="/biblioteca/BSD" className="enlace font-bold">
                          {" "}
                          Santo Domingo
                        </a>
                      </li>
                      <li>
                        <FaArrowRight className="inline" />
                        <a href="/biblioteca/BCH" className="enlace font-bold">
                          {" "}
                          Campus Clínico
                        </a>
                      </li>
                      <li>
                        <FaArrowRight className="inline" />
                        <a href="/biblioteca/ITTA" className="enlace font-bold">
                          {" "}
                          Sede Talcahuano
                        </a>
                      </li>
                      <li>
                        <FaArrowRight className="inline" />
                        <a href="/biblioteca/ITCH" className="enlace font-bold">
                          {" "}
                          Sede Chillán
                        </a>
                      </li>
                      <li>
                        <FaArrowRight className="inline" />
                        <a href="/biblioteca/ITCA" className="enlace font-bold">
                          {" "}
                          Sede Cañete
                        </a>
                      </li>
                      <li>
                        <FaArrowRight className="inline" />
                        <a href="/biblioteca/ITLA" className="enlace font-bold">
                          {" "}
                          Sede Los Ángeles
                        </a>
                      </li>
                    </ul>
                  </div>
                  <p className="text-justify font-bold mt-2">
                    ¿Cómo solicitar un préstamo Intercampus?
                  </p>
                  <p className="text-justify">
                    Indica a la jefatura de Biblioteca correspondiente a tu sede
                    los datos del material que necesitas (título, autor, nº de
                    pedido y el nombre de la Btca. propietaria) para gestionar
                    la solicitud del préstamo y cuando llegue el libro te lo
                    notificará. El tiempo de respuesta es de 5 días hábiles, y
                    tienes igualmente la opción de renovar el material.
                  </p>
                  <p className="text-justify font-bold">
                    ¿Cómo solicitar un préstamo Interbibliotecario?
                  </p>
                  <p className="text-justify">
                    Las Bibliotecas pertenecientes a Universidades del CRUCH, a
                    través de su convenio de cooperación, disponen para sus
                    usuarios el servicio de préstamo Interbibliotecario (PIB)
                    que posibilita el préstamo a domicilio y consulta en sala de
                    material bibliográfico entre estas casas de estudio,
                    dependiendo de las normas que establece cada institución
                    para esos fines. Es importante tener en cuenta que los
                    costos de envío son de exclusiva responsabilidad del usuario
                    que realiza la solicitud, cuyos montos están sujetos a las
                    tarifas que determine la entidad encargada del traslado del
                    material.
                  </p>
                  <p>Instrucciones:</p>
                  <div>
                    <ul className="list-group list-group-flush">
                      <li className="text-justify">
                        - Una vez determinado el título y la institución
                        propietaria del material, acércate a la jefatura de
                        Biblioteca correspondiente a tu sede con la información
                        del recurso que deseas solicitar.
                      </li>
                      <li className="mt-3 text-justify">
                        Dependiendo el caso, si tu opción es revisar material en
                        sala, la jefatura de Biblioteca emitirá un formulario de
                        préstamo Interbibliotecario que acredita la calidad del
                        usuario, junto con la información del o los recursos a
                        consultar. Es importante tener presente que debes
                        presentar el certificado de préstamo Interbibliotecario
                        acompañado de tu cédula de identidad.
                      </li>
                      <li className="mt-3 text-justify">
                        En caso que requieras el material para llevarlo a tu
                        domicilio, la jefatura de Biblioteca correspondiente a
                        tu sede, internamente realizará las gestiones con la
                        Biblioteca propietaria para coordinar el envío bajo las
                        condiciones establecidas para este tipo de solicitudes.{" "}
                      </li>
                    </ul>
                  </div>
                  <p className="text-justify font-bold mt-2">
                    {" "}
                    ¿Cómo solicitar un certificado de No deuda de material
                    bibliográfico?
                  </p>
                  <p className="text-justify">
                    El certificado de no deuda de material bibliográfico es un
                    documento que acredita y confirma que el usuario/a
                    solicitante no mantiene pendiente devolución de ningún tipo
                    de material en su cuenta de Biblioteca.{" "}
                  </p>
                  <p className="text-justify">
                    La solicitud de este documento se puede realizar tanto en
                    modalidad presencial como virtual (a través de un correo
                    electrónico) dirigido a la jefatura de Biblioteca
                    correspondiente a tu sede, quién verificará la información a
                    través del Sistema de Gestión de Biblioteca y posteriormente
                    entregará o enviará el certificado según corresponda.
                  </p>
                  <p className="mt-3 text-justify font-bold">
                    Si no tienes conocimiento de quien es la jefatura de
                    Biblioteca correspondiente a tu sede, te dejamos la
                    información:
                  </p>
                  <div>
                    <ul className="list-group list-group-flush">
                      <li>
                        <FaArrowRight className="inline" />
                        <a href="/biblioteca/BSA" className="enlace font-bold">
                          {" "}
                          San Andrés
                        </a>
                      </li>
                      <li>
                        <FaArrowRight className="inline" />
                        <a href="/biblioteca/BSD" className="enlace font-bold">
                          {" "}
                          Santo Domingo
                        </a>
                      </li>
                      <li>
                        <FaArrowRight className="inline" />
                        <a href="/biblioteca/BCH" className="enlace font-bold">
                          {" "}
                          Campus Clínico
                        </a>
                      </li>
                      <li>
                        <FaArrowRight className="inline" />
                        <a href="/biblioteca/ITTA" className="enlace font-bold">
                          {" "}
                          Sede Talcahuano
                        </a>
                      </li>
                      <li>
                        <FaArrowRight className="inline" />
                        <a href="/biblioteca/ITCH" className="enlace font-bold">
                          {" "}
                          Sede Chillán
                        </a>
                      </li>
                      <li>
                        <FaArrowRight className="inline" />
                        <a href="/biblioteca/ITCA" className="enlace font-bold">
                          {" "}
                          Sede Cañete
                        </a>
                      </li>
                      <li>
                        <FaArrowRight className="inline" />
                        <a href="/biblioteca/ITLA" className="enlace font-bold">
                          {" "}
                          Sede Los Ángeles
                        </a>
                      </li>
                    </ul>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6" className="mt-2">
                <Accordion.Header>
                  ¿Cómo solicitar asesoría relacionada con el Servicio de Apoyo
                  a la Investigación?
                </Accordion.Header>
                <Accordion.Body>
                  <p className="text-justify">
                    El Servicio de Apoyo a la Investigación (SAI) es el área
                    encargada de otorgar una respuesta integral de apoyo y
                    asesoría para satisfacer las demandas exigidas en el área de
                    la investigación científica universitaria. Este servicio, se
                    encuentra dirigido a la comunidad académica e investigativa
                    de la Universidad, como también al estudiantado de pregrado
                    y postgrado que se encuentre realizando actividades de
                    investigación.
                  </p>
                  <p className="text-justify font-bold">
                    Para más información sobre el servicio, te invitamos a
                    revisar la sección de Apoyo a la Investigación
                  </p>
                  <p className="text-justify font-bold">
                    Para contactar a nuestra especialista de Apoyo a la
                    investigación te dejamos la siguiente información de
                    contacto: Morella Rodríguez: morodriguez@ucsc.cl
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7" className="mt-2">
                <Accordion.Header>
                  ¿Qué hacer si pierdo un recurso de la Biblioteca?
                </Accordion.Header>
                <Accordion.Body>
                  <p className="text-justify font-bold">
                    En caso de que te encuentres en esta situación te dejamos la
                    siguiente información
                  </p>
                  <p className="text-justify">
                    Según lo establecido en el Reglamento de Biblioteca, todo
                    tipo de material perdido, dañado o extraviado debe ser
                    repuesto por el usuario/a responsable de la cuenta.
                  </p>
                  <p className="text-justify">
                    Para reposición del material, debes acercarte a la jefatura
                    de Biblioteca correspondiente a tu sede quien te explicara
                    el procedimiento, las opciones y los plazos establecidos
                    para la reposición del material.
                  </p>
                  <p className="text-justify">
                    La no reposición del material conlleva a situaciones como la
                    imposibilidad de inscribir ramos para el siguiente semestre,
                    la desaprobación de solicitudes de renuncia y/o suspensión
                    de la carrera e incluso la postergación en el proceso de
                    titulación mientras no sea repuesto el material.
                  </p>
                  <p className="text-justify">
                    Si necesitas más información, puedes contactarnos al
                    siguiente correo electrónico: sibucsc@ucsc.cl
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <br></br>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Questions;

import { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../css/Events.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import logoSIBUCSC from "../../assets/images/home/navbar/SIBUCSC.png";
import { FaDownload, FaArrowRight } from "react-icons/fa";
import axios from "axios";

const Events = () => {
  const [eventos, setEventos] = useState();
  const [filteredList, setFilteredList] = useState();
  const [filteredLinks, setFilteredLinks] = useState();

  const [filterEvent, setFilterEvent] = useState([]);

  const [show, setShow] = useState(false);

  const getApiDataEvents = async () => {
    const response = await axios
      .get("https://sibucsc.cl/sibucsc/noticias/index/?limit=5&tipo=2", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setEventos(response.data.results);
        setFilteredList(response.data.results);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getApiDataEvents();
  }, []);

  const handleClose = () => setShow(false);
  function handleShow(i) {
    setShow(true);
    setFilterEvent(filteredList[i]);
    setFilteredLinks(filteredList[i].enlaces);
  }

  function HEADER_TITLE() {
    return {
      color: "#646567",
      fontWeight: "999",
    };
  }

  function HEADER_MODAL() {
    return {
      backgroundColor: "#FFF",
      color: "black",
    };
  }

  const getImg = (img) => {
    try {
      return (
        <img
          alt=""
          className="imgAnnounc"
          src={require(`../../assets/images/${img}`)}
        />
      );
    } catch (err) {
      return (
        <img
          alt=""
          className="imgAnnounc"
          src={require(`../../assets/images/images_noticias/sin-foto.png`)}
        />
      );
    }
  };

  return (
    <>
      <div>
        <span className="col d-flex justify-content-center mt-4 ms-md-3 titleInfoEvents font-bold">
          ANUNCIOS DESTACADOS
        </span>
      </div>
      <Carousel className="mt-3">
        {eventos &&
          eventos.map((evento, i) => (
            <div className="divEvents" key={i}>
              {getImg(evento.imagen)}
              <div className="overlay">
                <div className="description">
                  <h4 className="font-bold">{evento.titulo}</h4>
                  <p className="font-bold">{evento.fecha_publicacion}</p>
                  <p>
                    <button
                      className="bg-[#d12421] hover:bg-red-700 text-lg font-bold py-2 px-4 rounded-full mt-4"
                      onClick={() => handleShow(i)}
                    >
                      Más información
                    </button>
                  </p>
                </div>
              </div>
            </div>
          ))}
      </Carousel>
      <Modal show={show} size="lg" centered>
        <Modal.Header style={HEADER_MODAL()}>
          <Modal.Title className="text-center" style={HEADER_TITLE()}>
            {filterEvent.titulo}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-justify mt-2">
            <span className="descriptionEvent"> {filterEvent.descripcion}</span>
          </div>
          {filteredLinks &&
            filteredLinks.map((link, i) => (
              <div className="mt-3" key={i}>
                <p>
                  {" "}
                  <a
                    href={
                      link.link.startsWith("http")
                        ? link.link
                        : `https://${link.link}`
                    }
                    className="enlace"
                    target="_blank"
                  >
                    {link.descripcion}
                  </a>
                </p>
              </div>
            ))}
          <span className="mt-1 d-flex justify-content-end font-bold">
            {filterEvent.fecha_publicacion}
          </span>
        </Modal.Body>
        <Modal.Footer style={{ float: "left" }}>
          <div className="w-100">
            <img src={logoSIBUCSC} className="h-10 bannerModal"></img>
            <Button variant="secondary float-right" onClick={handleClose}>
              Cerrar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Events;

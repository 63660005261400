import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "../../css/Resources.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Banner from "./Banner";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Pl from "./Plagiarism";
import PGD from "./DataManagement";
import Pr from "./PrePrints";
import RD from "./JournalDep";
import RP from "./JournalPub";

const Support = () => {
  return (
    <>
      <Banner />
      <Container className="web-container pt-3">
        <div className="col d-flex justify-content-center">
          <span className="font-bold titleInfoInv">
            Herramientas de Publicación
          </span>
        </div>
        <Row>
          <Col>
            <Tabs
              defaultActiveKey="Pl"
              id="tabHP"
              className="navT mb-3 mt-5"
              fill
            >
              <Tab eventKey="Pl" title="Plagio">
                <Pl />
              </Tab>
              <Tab eventKey="PGD" title="Plan de Gestión de Datos (PGD)">
                <PGD />
              </Tab>
              <Tab eventKey="Pr" title="Preprints">
                <Pr />
              </Tab>
              <Tab eventKey="RD" title="Revistas depredadoras">
                <RD />
              </Tab>
              <Tab eventKey="RP" title="Revistas para publicar">
                <RP />
              </Tab>
            </Tabs>
          </Col>
        </Row>
        <br></br>
        <br></br>
      </Container>
    </>
  );
};
export default Support;

import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "../../css/Resources.css";
import Card from "react-bootstrap/Card";
import axios from "axios";
import { useEffect, useState } from "react";

const Tutorials = () => {
  const [tutoriales, setTutoriales] = useState();

  const getApiDataTutoriales = async () => {
    const response = await axios
      .get(
        "https://sibucsc.cl/sibucsc/recursos/index/?id_rec=&nombre=&descripcion=&url=&categorias__categoria=30",
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setTutoriales(response.data.results);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getApiDataTutoriales();
  }, []);

  const getImg = (img) => {
    try {
      return (
        <Card.Img
          variant="top"
          src={require(`../../assets/${img}`)}
          style={{ width: "310px", height: "130px" }}
        />
      );
    } catch (err) {
      return (
        <Card.Img
          variant="top"
          src={require(`../../assets/images/resources/sin-foto.png`)}
          style={{ width: "310px", height: "130px" }}
        />
      );
    }
  };

  const getDoc = (doc) => {
    try {
      return (
        <a
          className="enlace font-bold"
          href={require(`../../assets/${doc}`)}
          target="_blank"
        >
          Acceder a presentación
        </a>
      );
    } catch (err) {
      return (
        <a
          className="enlace font-bold"
          href="/investigacion/tutoriales"
          target="_blank"
        >
          Acceder a presentación
        </a>
      );
    }
  };

  return (
    <>
      <Container fluid className="web-container">
        <div>
          <div className="m-3">
            <h3 className="main-heading text-white">
              Capacitaciones y tutoriales
            </h3>
            <h6 className="text-justify font-bold text-[#646567]">
              Con el fin de promover el aprendizaje continuo de nuestra
              comunidad académica ofrecemos una serie de talleres y curso de
              corta duración (max 2 horas) que han sido impartidos desde nuestro
              Servicio de apoyo a la Investigación (SAI) en fechas anteriores en
              diversos temas de interés del quehacer investigativo, tales como,
              gestores bibliográficos, herramientas antiplagio, creación y
              actualización de perfiles de investigación, herramientas de
              publicación de artículos científicos, entre otros, que podrá
              visualizar cuando desee.
            </h6>
          </div>
        </div>
        <Row className="row-lib pt-2">
          <Col lg={12} md={12} xs={12}>
            <div className="divInformacion">
              <Row>
                {tutoriales &&
                  tutoriales.map((tut, i) => (
                    <div className="col d-flex justify-content-center" key={i}>
                      <Card
                        style={{ width: "16rem" }}
                        className="ms-3 mt-3 cardResources"
                        key={i}
                      >
                        {getImg(tut.img)}

                        <Card.Body>
                          <Card.Title className="spanText titTut">
                            {tut.nombre}
                          </Card.Title>

                          <footer className="blockquote-footer">
                            <p className="mt-3">
                              <a
                                className="enlace font-bold "
                                href={tut.url}
                                target="_blank"
                              >
                                Acceder al contenido
                              </a>
                            </p>
                            <div className="mt-3">
                              {tut.doc.includes("docs/resources/") ? (
                                <p className="mt-1">
                                  {tut.doc && <div> {getDoc(tut.doc)}</div>}
                                </p>
                              ) : (
                                <p className="mt-1">
                                  {tut.doc && (
                                    <a
                                      className="enlace font-bold"
                                      href={tut.doc}
                                      target="_blank"
                                    >
                                      Acceder a presentación
                                    </a>
                                  )}
                                </p>
                              )}
                            </div>
                          </footer>
                        </Card.Body>
                      </Card>
                    </div>
                  ))}
              </Row>
            </div>
          </Col>
        </Row>
        <br></br>
      </Container>
    </>
  );
};
export default Tutorials;

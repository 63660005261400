import React from "react";
import Banner from "../../assets/images/banner/banner_sai.png";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const BannerContainer = () => {
  return (
    <Container fluid className="web-container pt-3" id="bannerSIBUCSC">
      <Row className="col d-flex justify-content-center">
        <Col xl={4}>
          <div className="col d-flex justify-content-center">
            <img src={Banner} id="imgBanner"></img>
          </div>
        </Col>
        <Col xl={6} className="mt-md-5">
          <div>
            <div className="m-3">
              <h3 className="main-heading text-white">
                Servicio de Apoyo a la Investigación
              </h3>
              <h6 className="text-justify text-[#C9CCD2]">
                El Sistema de Bibliotecas pone a disposición de sus usuarios,
                una variedad de servicios que van ligados a la mejora en su
                desarrollo de competencias informacionales
              </h6>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default BannerContainer;

import "../../css/Repo.css";
import { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import "../../css/Newsletters.css";
import { FaDownload } from "react-icons/fa";
import { useParams } from "react-router-dom";
import axios from "axios";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import markerIconPng from "../../assets/images/library/marker.png";
import { FaPhone, FaMailBulk, FaMapMarkerAlt } from "react-icons/fa";
import Slider from "react-slick";

const NewsAndMap = ({
  lat,
  long,
  direccion,
  fono,
  codigo_postal,
  biblioteca,
}) => {
  const [boletines, setBoletines] = useState();
  const params = useParams();

  if (params.abreviatura == "BSA") {
    lat = -36.7982872;
    long = -73.0565312;
    direccion = "Alonso de Ribera #2850";
    fono = "+56 412345530";
    codigo_postal = "4070129";
    biblioteca = "Biblioteca San Andrés";
  } else if (params.abreviatura == "BSD") {
    lat = -36.8305661;
    long = -73.0520281;
    direccion = "Lincoyan #255";
    fono = "+56 412345600";
    codigo_postal = "4070258";
    biblioteca = "Biblioteca Sede Santo Domingo";
  } else if (params.abreviatura == "BCH") {
    lat = -36.6094995;
    long = -72.0924288;
    direccion = "Francisco Ramirez #10";
    fono = "+56 422248308";
    codigo_postal = "(sin codigo postal)";
    biblioteca = "Biblioteca Campus Clínico Chillán";
  } else if (params.abreviatura == "ITTA") {
    lat = -36.7366063;
    long = -73.1042436;
    direccion = "Colon #2766";
    fono = "+56 412345823";
    codigo_postal = "4261202";
    biblioteca = "Biblioteca Sede Talcahuano";
  } else if (params.abreviatura == "ITCH") {
    lat = -36.6057192;
    long = -72.1018951;
    direccion = "Arauco #449";
    fono = "+56 412345713";
    codigo_postal = "3800676";
    biblioteca = "Biblioteca Sede Chillán";
  } else if (params.abreviatura == "ITCA") {
    lat = -37.792403;
    long = -73.3903886;
    direccion = "Av. Eduardo Frei #787";
    fono = "+56 412345775";
    codigo_postal = "(sin codigo postal)";
    biblioteca = "Biblioteca Sede Cañete";
  } else if (params.abreviatura == "ITLA") {
    lat = -37.4714251;
    long = -72.3492091;
    direccion = "Caupolicán #276";
    fono = "+56 43345879";
    codigo_postal = "4441185";
    biblioteca = "Biblioteca Los Ángeles";
  }

  const getApiData = async () => {
    const response = await axios
      .get(
        `https://sibucsc.cl/sibucsc/boletines/index/?limit=5&campus__abreviatura=` +
          params.abreviatura +
          `&numero=&ordering=-numero`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setBoletines(response.data.results);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getApiData();
  }, []);

  const getImg = (img) => {
    try {
      return (
        <Card.Img
          variant="top"
          src={require(`../../assets/${img}`)}
          className="imgNews"
        />
      );
    } catch (err) {
      return (
        <Card.Img
          variant="top"
          src={require(`../../assets/images/newsletters/sin-foto.png`)}
          className="imgNews"
        />
      );
    }
  };

  const getDoc = (doc) => {
    try {
      return (
        <a
          href={require(`../../assets/${doc}`)}
          className="font-bold footerSingular urlBoletin text-[#d12421]"
          target="_blank"
        >
          <FaDownload className="inline" /> Ver Boletín
        </a>
      );
    } catch (err) {
      return (
        <a
          href="/"
          className="font-bold footerSingular urlBoletin text-[#d12421]"
          target="_blank"
        >
          <FaDownload className="inline" /> Ver Boletín
        </a>
      );
    }
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1055,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  const Map = (
    <Col md={12}>
      <Card className="border shadow-lg m-5">
        <Card.Body>
          <MapContainer
            style={{ width: "auto", height: "400px" }}
            center={[lat, long]}
            zoom={17}
            id="map"
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://sibucsc.cl">SIBUCSC</a>'
            />
            <Marker
              position={[lat, long]}
              icon={
                new Icon({
                  iconUrl: markerIconPng,
                  iconSize: [50, 50],
                  iconAnchor: [30, 30],
                })
              }
            >
              <Popup openPopup={true}>{biblioteca}</Popup>
            </Marker>
          </MapContainer>
          <Card.Text className="mt-3">
            <span>
              <b>
                <FaMapMarkerAlt className="m-1 inline" />
                Dirección:
              </b>{" "}
              {direccion}
            </span>
            <br></br>
            <span>
              <b>
                <FaPhone className="m-1 inline" />
                Fono:
              </b>{" "}
              {fono}
            </span>
            <br></br>
            <span>
              <b>
                <FaMailBulk className="m-1 inline" />
                Código Postal:
              </b>{" "}
              {codigo_postal}
            </span>
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );

  return (
    <Container fluid>
      <Row className="marginNews">
        <Col md={boletines == "" ? 12 : 6}>
          <div className="divNewsletters position-relative ">
            <h4 className="mt-4 ms-md-5 ms-3 titleInfoInteres ">
              {boletines != "" ? "BOLETINES" : ""}
            </h4>
            {boletines != "" ? (
              <Slider {...settings} className="m-2">
                {boletines &&
                  boletines.map((boletin, i) => (
                    <Col key={i} className="colNew">
                      <Card className="cardNewLibrary m-2">
                        {getImg(boletin.url_img)}
                        <Card.Body>
                          <Card.Title className="titNewsletters text-[#646567]">
                            {boletin.nombre}
                          </Card.Title>
                          <div>
                            <p className="mt-4">{boletin.descripcion}</p>
                            <p>{getDoc(boletin.url_pdf)}</p>
                          </div>
                        </Card.Body>
                      </Card>
                      <br></br>
                    </Col>
                  ))}
              </Slider>
            ) : (
              <div></div>
            )}
          </div>
        </Col>
        {boletines == "" ? <Col md={12}>{Map}</Col> : <Col md={6}> {Map} </Col>}
      </Row>
      <br></br>
    </Container>
  );
};

export default NewsAndMap;

import React, { useState, useRef, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "../../css/Request.css";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Swal from "sweetalert2";
import ChileanRutify from "chilean-rutify";
import Banner from "../../assets/images/banner/banner_request_art.png";
import moment from "moment";
import ReCAPTCHA from "react-google-recaptcha";
import {
  CCol,
  CFormInput,
  CFormTextarea,
  CRow,
  CForm,
  CFormLabel,
} from "@coreui/react";

const Articles = () => {
  const [email, setEmail] = useState();
  const [rut, setRut] = useState();
  const [comentarios, setComentarios] = useState();
  const [titulo, setTitulo] = useState();
  const [revista, setRevista] = useState();
  const [tipo_usuario, setTipoUsuario] = useState();
  const [autor, setAutor] = useState();
  const [ejemplar, setEjemplar] = useState();
  const recaptcha = useRef();
  const fecha_sol = moment().format("YYYY-MM-DD");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const captchaValue = recaptcha.current.getValue();
    if (captchaValue) {
      if (ChileanRutify.validRut(rut) === true) {
        // Handle validations
        Swal.fire({
          title: "Enviando consulta",
          html: "Espere por favor",
          didOpen: () => {
            Swal.showLoading();
          },
        });
        try {
          const loginResponse = await fetch(
            "https://sibucsc.cl/sibucsc/roundtable/token/",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                username: "gallahad",
                password: "f0rms-lancel0t",
              }),
            }
          );

          if (!loginResponse.ok) {
            throw new Error("Login failed");
          }

          const data = await loginResponse.json();

          const response = await axios.post(
            "https://sibucsc.cl/sibucsc/solarticulo/index/",
            {
              rut,
              email,
              comentarios,
              titulo,
              revista,
              tipo_usuario,
              autor,
              ejemplar,
              fecha_sol,
            },
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + data.access,
              },
            }
          );
          if (response.status === 201) {
            Swal.fire(
              "Solicitud enviada con éxito",
              "presione OK para terminar",
              "success"
            ).then(() => {
              window.location.reload();
            });
          }
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Error al enviar la solicitud, intente nuevamente",
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Rut incorrecto, intente nuevamente",
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Por favor verifica reCAPTCHA!",
      });
    }
  };

  return (
    <>
      <Container fluid className="web-container pt-3" id="bannerSIBUCSC">
        <Row className="col d-flex justify-content-center">
          <Col xl={4}>
            <div className="col d-flex justify-content-center">
              <img src={Banner} id="imgBanner"></img>
            </div>
          </Col>
          <Col xl={6} className="mt-md-5">
            <div>
              <div className="m-3">
                <h3 className="main-heading text-white">
                  Solicitud de Artículos
                </h3>
                <h6 className="text-justify text-[#C9CCD2]">
                  El Sistema de Bibliotecas pone a disposición el servicio de
                  suministro de revistas impresas y electrónicas con artículo a
                  texto completo. Si no lograste descargar el artículo a Texto
                  completo, completa el formulario y te contactaremos. Este
                  servicio está abierto a todos los investigadores, docentes,
                  estudiantes de pregrado y postgrado de la Universidad Católica
                  de Santísima Concepción.
                </h6>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div>
        <Container fluid className="web-container">
          <Row className="col d-flex justify-content-center">
            <Col xl={12} sm={12} md={12} xs={12}></Col>
          </Row>
          <Row className="col d-flex justify-content-center m-4">
            <Col xl={8} sm={12} md={12} xs={12}>
              <CForm
                className="border p-3"
                action=""
                method="post"
                onSubmit={handleSubmit}
              >
                <h5 className="text-[#d12421] font-bold">
                  Formulario de solicitud
                  <hr></hr>
                </h5>
                <Row>
                  <Col xl={6} sm={12} md={12} xs={12}>
                    <CRow className="mb-3 ">
                      {/* INPUT RUT */}
                      <CFormLabel
                        htmlFor="rut"
                        className="col-sm-3 col-form-label"
                      >
                        Rut
                      </CFormLabel>
                      <CCol sm={9}>
                        <CFormInput
                          type="text"
                          id="rut"
                          maxLength={9}
                          required
                          value={rut}
                          onChange={(e) => setRut(e.target.value)}
                          placeholder="RUT sin puntos ni guión, Ej: 12345678K"
                        />
                      </CCol>
                      {/* CIERRE INPUT RUT */}
                    </CRow>
                    <CRow className="mb-3 ">
                      {/* INPUT EMAIL */}
                      <CFormLabel
                        htmlFor="email"
                        className="col-sm-3 col-form-label"
                      >
                        Email
                      </CFormLabel>
                      <CCol sm={9}>
                        <CFormInput
                          type="email"
                          id="email"
                          maxLength={250}
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Ingrese su email aquí"
                        />
                      </CCol>
                      {/* CIERRE INPUT EMAIL */}
                    </CRow>
                    <CRow className="mb-3 ">
                      {/* INPUT TITULO ARTICULO */}
                      <CFormLabel
                        htmlFor="taller"
                        className="col-sm-3 col-form-label"
                      >
                        Título de Artículo
                      </CFormLabel>
                      <CCol sm={9}>
                        <CFormInput
                          type="text"
                          id="tituloArticulo"
                          maxLength={250}
                          value={titulo}
                          onChange={(e) => setTitulo(e.target.value)}
                          placeholder="Ingrese título de artículo aquí"
                        />
                      </CCol>
                      {/* CIERRE INPUT TITULO ARTICULO */}
                    </CRow>
                    <CRow className="mb-3 ">
                      {/* INPUT TITULO REVISTA */}
                      <CFormLabel
                        htmlFor="taller"
                        className="col-sm-3 col-form-label"
                      >
                        Título de Revista
                      </CFormLabel>
                      <CCol sm={9}>
                        <CFormInput
                          type="text"
                          id="tituloRevista"
                          maxLength={250}
                          value={revista}
                          onChange={(e) => setRevista(e.target.value)}
                          placeholder="Ingrese título de revista aquí"
                        />
                      </CCol>
                      {/* CIERRE INPUT REVISTA */}
                    </CRow>
                  </Col>
                  <CCol xl={6} sm={12} md={12} xs={12}>
                    <CRow className="mb-3 ">
                      {/* INPUT TIPO USUARIO */}
                      <CFormLabel
                        htmlFor="taller"
                        className="col-sm-3 col-form-label"
                      >
                        Tipo de usuario
                      </CFormLabel>
                      <CCol sm={9}>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          value={tipo_usuario}
                          onChange={(e) => setTipoUsuario(e.target.value)}
                        >
                          <option selected>Seleccione tipo de usuario</option>
                          <option value="Pregrado">Pregrado</option>
                          <option value="Postgrado">Postgrado</option>
                          <option value="Academico">Académico</option>
                          <option value="Investigador">Investigador</option>
                        </select>
                      </CCol>
                      {/* CIERRE TIPO USUARIO */}
                    </CRow>
                    <CRow className="mb-3 ">
                      {/* INPUT AUTOR TITULO */}
                      <CFormLabel
                        htmlFor="taller"
                        className="col-sm-3 col-form-label"
                      >
                        Autor Título
                      </CFormLabel>
                      <CCol sm={9}>
                        <CFormInput
                          type="text"
                          id="autorTitulo"
                          maxLength={250}
                          value={autor}
                          onChange={(e) => setAutor(e.target.value)}
                          placeholder="Ingrese autor aquí"
                        />
                      </CCol>
                      {/* CIERRE AUTOR TITULO */}
                    </CRow>
                    <CRow className="mb-3 ">
                      {/* INPUT EJEMPLAR REVISTA */}
                      <CFormLabel
                        htmlFor="taller"
                        className="col-sm-3 col-form-label"
                      >
                        Ejemplar Revista
                      </CFormLabel>
                      <CCol sm={9}>
                        <CFormInput
                          type="text"
                          id="ejemplarRevista"
                          maxLength={250}
                          value={ejemplar}
                          onChange={(e) => setEjemplar(e.target.value)}
                          placeholder="Ingrese ejemplar de la revista aquí"
                        />
                      </CCol>
                      {/* CIERRE EJEMPLAR REVISTA */}
                    </CRow>
                    <CRow className="mb-3 ">
                      {/* INPUT COMENTARIOS */}
                      <CFormLabel
                        htmlFor="comentarios"
                        className="col-sm-3 col-form-label"
                      >
                        Comentarios (opcional)
                      </CFormLabel>
                      <CCol sm={9}>
                        <CFormTextarea
                          type="email"
                          id="comentarios"
                          value={comentarios}
                          maxLength={250}
                          placeholder="Agrege información adicional como por ej: DOI (max 250 carácteres)"
                          onChange={(e) => setComentarios(e.target.value)}
                        />
                      </CCol>
                      {/* CIERRE COMENTARIOS */}
                    </CRow>
                    <CCol xl={1} sm={12} md={12} xs={12}>
                      <div className="col d-flex justify-content-center">
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_SITE_KEY}
                          ref={recaptcha}
                        />
                      </div>
                    </CCol>
                  </CCol>
                  <div className="col d-flex mt-3 justify-content-center">
                    <Button
                      type="submit"
                      variant="outline-danger"
                      className="btnAnnounc"
                    >
                      Enviar
                    </Button>
                  </div>
                </Row>
              </CForm>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default Articles;

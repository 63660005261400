import "../../css/Repo.css";
import booksDigital from "../../assets/images/home/biblioteca_digitalizada_01.png";
import { Tooltip as ReactTooltip } from "react-tooltip";

const BooksDigital = () => {
  return (
    <div className="divRepo" data-tooltip-id="my-tooltip-books">
      <a
        href="https://go.openathens.net/redirector/ucsc.cl?url=https%3A%2F%2Fbibliografia.ucsc.cl"
        target="_blank"
        className="hrefRepo"
      >
        <h4 className="font-bold titleRepo text-center text-[#fff]">
          Bibliografía Digitalizada
        </h4>
        <div className="col d-flex justify-content-center">
          <img src={booksDigital} alt="" className="imgBiblio mt-2" />
        </div>
        <div id="descRepos">
          <ReactTooltip
            id="my-tooltip-books"
            className="tooltipRepos"
            place="bottom"
            variant="info"
            content="Aquí encontrarás el acceso a títulos digitales de la bibliografía
            básica solicitada por los distintos programas de estudios impartidos
            por nuestra Universidad."
          />
        </div>
      </a>
    </div>
  );
};

export default BooksDigital;

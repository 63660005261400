import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "../../css/Investigation.css";
import ListGroup from "react-bootstrap/ListGroup";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import logoSIBUCSC from "../../assets/images/home/navbar/SIBUCSC.png";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Banner from "./Banner";

const Researcher = () => {
  const [talleres, setTalleres] = useState();
  const [enlaces, setEnlaces] = useState();
  const [show, setShow] = useState(false);
  const [filterTaller, setFilterTaller] = useState([]);
  const [filteredList, setFilteredList] = useState();
  const handleClose = () => setShow(false);

  const getApiDataTalleres = async () => {
    const response = await axios
      .get("https://sibucsc.cl/sibucsc/talleres/index/", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const jp = require("jsonpath");
        const listTalleres = jp.query(
          response.data.results,
          "$..[?(@.categoria==1 || @.categoria==2 || @.categoria==3)]"
        );
        setTalleres(listTalleres);
        setFilteredList(listTalleres);
      })
      .catch((err) => {});
  };

  const getApiDataEnlaces = async () => {
    const response = await axios
      .get("https://sibucsc.cl/sibucsc/recursos/index/sai/", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setEnlaces(response.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getApiDataTalleres();
    getApiDataEnlaces();
  }, []);

  function handleShowTaller(i) {
    setShow(true);
    setFilterTaller(filteredList[i]);
  }

  const renderTooltip = (descripcion) => (
    <Tooltip id="button-tooltip">{descripcion}</Tooltip>
  );

  return (
    <>
      <Banner />
      <Container>
        <div className="col d-flex justify-content-center">
          <span className="font-bold titleInfoInv">
            Talleres y enlaces de interés
          </span>
        </div>
        <Row>
          <Col lg={7} md={6} xs={12}>
            <h3 className="text-[#646567] font-bold mt-4">Talleres</h3>
            <ListGroup as="ol" numbered className="mt-3">
              {talleres &&
                talleres.map((taller, i) => (
                  <ListGroup.Item as="li" key={i}>
                    <a
                      className="hrefTalleres font-bold"
                      onClick={() => handleShowTaller(i)}
                    >
                      {taller.nombre}
                    </a>
                  </ListGroup.Item>
                ))}{" "}
            </ListGroup>
            <Modal show={show} size="lg" centered>
              <Modal.Header>
                <Modal.Title className="text-center">
                  {" "}
                  {filterTaller.nombre}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className="font-bold">Objetivo</p>
                <p className="text-justify">{filterTaller.objetivo}</p>
                <p className="font-bold">Contenidos</p>
                <p className="text-justify contents">
                  {filterTaller.contenido}
                </p>
                <p className="font-bold mt-4">Contacto: morodriguez@ucsc.cl.</p>
              </Modal.Body>
              <Modal.Footer style={{ float: "left" }}>
                <div className="w-100">
                  <img src={logoSIBUCSC} className="h-10 bannerModal"></img>
                  <Button variant="secondary float-right" onClick={handleClose}>
                    Cerrar
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
          </Col>
          <Col lg={5} md={12} xs={12}>
            <h3 className="text-[#646567] font-bold mt-4">
              Enlaces de Interés
            </h3>
            <>
              <ListGroup as="ol" numbered className="mt-3">
                {enlaces &&
                  enlaces.map((enlace, i) => (
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-start"
                      key={i}
                    >
                      <div className="ms-2 me-auto">
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip(enlace.descripcion)}
                        >
                          <div className="fw-bold">{enlace.nombre}</div>
                        </OverlayTrigger>
                      </div>
                      {enlace.img.length > 0 && (
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip(enlace.descripcion)}
                        >
                          <Badge
                            bg="bg-transparent"
                            className="badgeResources d-flex justify-content-center bg-[#D6625F]"
                          >
                            <a href={enlace.url} target="_blank">
                              <img
                                src={require(`../../assets/${enlace.img}`)}
                                className="h-10"
                              />
                            </a>
                          </Badge>
                        </OverlayTrigger>
                      )}
                    </ListGroup.Item>
                  ))}{" "}
              </ListGroup>
            </>
          </Col>
        </Row>
        <br></br>
      </Container>
    </>
  );
};
export default Researcher;

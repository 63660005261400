import React from "react";
import Col from "react-bootstrap/Col";
import imgHorarios from "../../assets/images/library/schedule.png";
import Row from "react-bootstrap/Row";
import { useParams } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";

const Schedule = () => {
  const params = useParams();
  const dataBSA = [
    {
      horarios: {
        horario1: "Lunes a Viernes: 08:00 a 20:00 hrs",
        horario2: "Sábado: 09:00 a 13:00 hrs",
      },
      descripcion: "Estantería Abierta y Reserva",
    },
    {
      horarios: {
        horario1: "Lunes a Jueves: 09:00 a 18:00 hrs",
        horario2: "Viernes: 09:00 a 17:00 hrs",
      },
      descripcion: "Hemeroteca y Teología",
    },
    {
      horarios: {
        horario1: "Lunes a Viernes: 08:00 a 20:00 hrs ",
        horario2: "Sábado: 09:00 a 13:00 hrs",
      },
      descripcion: "Sala de Dispositivos Electrónicos",
    },
    {
      horarios: {
        horario1: "Lunes a Viernes: 08:00 a 20:00 hrs",
      },
      descripcion: "Laboratorios de Computación",
    },
    {
      horarios: {
        horario1: "Lunes a Jueves: 09:00 a 17:45 hrs",
        horario2: "Viernes: 09:00 a 16:45 hrs",
      },
      descripcion: "Sala Tesistas",
    },
    {
      horarios: {
        horario1: "Lunes a Viernes: 08:00 a 20:00 hrs",
      },
      descripcion: "Salas de Estudio",
    },
  ];

  const dataBSD = [
    {
      horarios: {
        horario1: "Lunes a Jueves: 08:00 a 18:00 hrs",
        horario2: "Viernes: 08:00 a 17:00 hrs",
      },
      descripcion: "Público general",
    },
  ];

  const dataBCH = [
    {
      horarios: {
        horario1: "Lunes a Viernes: 09:00 a 13:00 hrs - 14:00 a 18:00 hrs",
        horario2: "Viernes: 08:00 a 17:00 hrs",
      },
      descripcion: "Público general",
    },
  ];

  const dataITTA = [
    {
      horarios: {
        horario1: "Lunes a Viernes: 09:00 a 21:00 hrs",
      },
      descripcion: "Público general",
    },
  ];

  const dataITCH = [
    {
      horarios: {
        horario1: "Lunes a Viernes: 08:30 a 21:30 hrs",
      },
      descripcion: "Público general",
    },
  ];

  const dataITCA = [
    {
      horarios: {
        horario1: "Lunes a Viernes: 09:00 a 21:30 hrs",
        horario2: "Sábado: 09:30 a 13:00 hrs",
      },
      descripcion: "Público general",
    },
  ];

  const dataITLA = [
    {
      horarios: {
        horario1: "Lunes a Viernes: 09:00 a 21:30 hrs",
        horario2: "Sábado: 09:00 a 13:00 hrs",
      },
      descripcion: "Público general",
    },
  ];
  return (
    <Col className="colHorarios">
      <img src={imgHorarios} alt="logo" className="imgHorario h-10 inline" />{" "}
      <h4 className="font-bold inline text-[#646567]">Nuestros horarios</h4>
      <div className="divHorario shadow-lg p-3 mt-3 border h-[auto]">
        <Row>
          {(() => {
            if (params.abreviatura == "BSA") {
              return dataBSA.map((dat, i) => (
                <Col xs={12} md={6} key={i}>
                  <Accordion defaultActiveKey="0" className="m-2">
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>{dat.descripcion}</Accordion.Header>
                      <Accordion.Body>
                        <div className="m-2">{dat.horarios.horario1}</div>
                        <div className="m-2">{dat.horarios.horario2}</div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
              ));
            } else if (params.abreviatura == "BSD") {
              return dataBSD.map((dat, i) => (
                <Col xs={12} md={12} key={i}>
                  <Accordion defaultActiveKey="0" className="m-2">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>{dat.descripcion}</Accordion.Header>
                      <Accordion.Body>
                        <div className="m-2">{dat.horarios.horario1}</div>
                        <div className="m-2">{dat.horarios.horario2}</div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
              ));
            } else if (params.abreviatura == "BCH") {
              return dataBCH.map((dat, i) => (
                <Col xs={12} md={12} key={i}>
                  <Accordion defaultActiveKey="0" className="m-2">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>{dat.descripcion}</Accordion.Header>
                      <Accordion.Body>
                        <div className="m-2">{dat.horarios.horario1}</div>
                        <div className="m-2">{dat.horarios.horario2}</div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
              ));
            } else if (params.abreviatura == "ITTA") {
              return dataITTA.map((dat, i) => (
                <Accordion defaultActiveKey="0" className="divAcc">
                  <Accordion.Item eventKey="0" className="divAcc">
                    <Accordion.Header>{dat.descripcion}</Accordion.Header>
                    <Accordion.Body>
                      <div className="m-2">{dat.horarios.horario1}</div>
                      <div className="m-2">{dat.horarios.horario2}</div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ));
            } else if (params.abreviatura == "ITCH") {
              return dataITCH.map((dat, i) => (
                <Accordion
                  defaultActiveKey="0"
                  className="m-2"
                  style={{ width: "100%" }}
                >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>{dat.descripcion}</Accordion.Header>
                    <Accordion.Body>
                      <div className="m-2">{dat.horarios.horario1}</div>
                      <div className="m-2">{dat.horarios.horario2}</div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ));
            } else if (params.abreviatura == "ITCA") {
              return dataITCA.map((dat, i) => (
                <Accordion defaultActiveKey="0" className="m-2">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>{dat.descripcion}</Accordion.Header>
                    <Accordion.Body>
                      <div className="m-2">{dat.horarios.horario1}</div>
                      <div className="m-2">{dat.horarios.horario2}</div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ));
            } else if (params.abreviatura == "ITLA") {
              return dataITLA.map((dat, i) => (
                <Accordion defaultActiveKey="0" className="m-2">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>{dat.descripcion}</Accordion.Header>
                    <Accordion.Body>
                      <div className="m-2">{dat.horarios.horario1}</div>
                      <div className="m-2">{dat.horarios.horario2}</div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ));
            }
          })()}
        </Row>
      </div>
    </Col>
  );
};

export default Schedule;

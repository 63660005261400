import Slider from "react-slick";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../css/Books.css";
import ArrowPrevious from "../../assets/images/home/left-arrow.svg";
import ArrowNext from "../../assets/images/home/right-arrow.svg";
import axios from "axios";
import Card from "react-bootstrap/Card";
import { FaDownload, FaArrowRight } from "react-icons/fa";

const ArrowButtonPrevious = ({ imgSrc, imgAlt, onClick }) => {
  return (
    <button
      onClick={onClick}
      style={{ backgroundColor: "white", border: "none" }}
    >
      <img
        src={imgSrc}
        alt={imgAlt}
        className="arrow-left"
        style={{
          width: "50px",
          height: "50px",
          marginTop: "-3%",
          marginRight: "85px",
        }}
      />
    </button>
  );
};

const ArrowButtonNext = ({ imgSrc, imgAlt, onClick }) => {
  return (
    <button
      onClick={onClick}
      style={{ backgroundColor: "white", border: "none" }}
    >
      <img
        src={imgSrc}
        alt={imgAlt}
        className="arrow-right"
        style={{
          width: "50px",
          height: "50px",
          marginTop: "-3%",
          marginRight: "20px",
        }}
      />
    </button>
  );
};

const getImg = (img) => {
  try {
    return (
      <Card.Img
        variant="top"
        src={require(`../../assets/${img}`)}
        className="imgNews"
      />
    );
  } catch (err) {
    return (
      <Card.Img
        variant="top"
        src={require(`../../assets/images/newsletters/sin-foto.png`)}
        className="imgNews"
      />
    );
  }
};

const getDoc = (doc) => {
  try {
    return (
      <a
        href={require(`../../assets/${doc}`)}
        className="font-bold footerSingular urlBoletin text-[#d12421]"
        target="_blank"
      >
        <FaDownload className="inline" style={{ fontSize: "20px" }} /> Ver
        Boletín
      </a>
    );
  } catch (err) {
    return (
      <a
        href="/"
        className="font-bold footerSingular urlBoletin text-[#d12421]"
        target="_blank"
      >
        <FaDownload className="inline" style={{ fontSize: "20px" }} /> Ver
        Boletín
      </a>
    );
  }
};

const Books = () => {
  const [boletines, setBoletines] = useState();
  const [hidden, setHidden] = useState(true);

  const getApiDataNews = async () => {
    const response = await axios
      .get("https://sibucsc.cl/sibucsc/boletines/index/", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setHidden(true);
        setBoletines(response.data.results);
      })
      .catch((err) => {
        setHidden(false);
      });
  };

  useEffect(() => {
    getApiDataNews();
  }, []);

  let settings = {
    prevArrow: (
      <ArrowButtonPrevious imgSrc={ArrowPrevious} imgAlt="previous-button" />
    ),
    nextArrow: <ArrowButtonNext imgSrc={ArrowNext} imgAlt="next-button" />,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    cssEase: "linear",
    initialSlide: 0,
    infinite: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1230,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 0,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Row>
      <div className="divBook">
        <span className="col d-flex justify-content-center mt-4 ms-md-3 titleInfoBoletines font-bold">
          BOLETINES
        </span>
        <div className="d-flex justify-content-center">
          <Card style={{ maxWidth: "30rem" }} hidden={hidden}>
            <Card.Body className="bg-[#BA4D58]">
              <Card.Title className="text-[#fff]">
                Información de Boletines actualmente no disponible
              </Card.Title>
            </Card.Body>
          </Card>
        </div>
        <Slider {...settings}>
          {boletines &&
            boletines.map((boletin, i) => (
              <Col key={i} className="card-wrapper flex-grow-1">
                <div className="col d-flex justify-content-center">
                  <Card className="cardNew ">
                    {getImg(boletin.url_img)}
                    <Card.Body>
                      <Card.Title
                        className="text-[#646567] mt-2 cardTitleNews"
                        style={{ fontSize: "15px" }}
                      >
                        {boletin.nombre}
                      </Card.Title>
                      <div className="cardDivNews">
                        <p className="mt-3">{getDoc(boletin.url_pdf)}</p>
                      </div>
                      <div>
                        {(() => {
                          if (boletin.campus == "1") {
                            return (
                              <a
                                href="/biblioteca/BSA"
                                className="font-bold  urlBoletin text-[#d12421]"
                              >
                                <FaArrowRight
                                  className="inline"
                                  style={{ fontSize: "20px" }}
                                />{" "}
                                Ver Boletines Sede San Andrés
                              </a>
                            );
                          } else if (boletin.campus == "2") {
                            return (
                              <a
                                href="/biblioteca/BSD"
                                className="font-bold footerSede urlBoletin text-[#d12421]"
                              >
                                <FaArrowRight
                                  className="inline"
                                  style={{ fontSize: "20px" }}
                                />{" "}
                                Ver Boletines Campus Santo Domingo
                              </a>
                            );
                          } else if (boletin.campus == "3") {
                            return (
                              <a
                                href="/biblioteca/BCH"
                                className="font-bold footerSede urlBoletin text-[#d12421]"
                              >
                                <FaArrowRight
                                  className="inline"
                                  style={{ fontSize: "20px" }}
                                />{" "}
                                Ver Boletines Campus Clínico Chillán
                              </a>
                            );
                          } else if (boletin.campus == "4") {
                            return (
                              <a
                                href="/biblioteca/ITTA"
                                className="font-bold footerSede urlBoletin text-[#d12421]"
                              >
                                <FaArrowRight
                                  className="inline"
                                  style={{ fontSize: "20px" }}
                                />{" "}
                                Ver Boletines Sede Talcahuano
                              </a>
                            );
                          } else if (boletin.campus == "5") {
                            return (
                              <a
                                href="/biblioteca/ITCH"
                                className="font-bold footerSede urlBoletin text-[#d12421]"
                              >
                                <FaArrowRight
                                  className="inline"
                                  style={{ fontSize: "20px" }}
                                />{" "}
                                Ver Boletines Sede Chillán
                              </a>
                            );
                          } else if (boletin.campus == "6") {
                            return (
                              <a
                                href="/biblioteca/ITLA"
                                className="font-bold footerSede urlBoletin text-[#d12421]"
                              >
                                <FaArrowRight
                                  className="inline"
                                  style={{ fontSize: "20px" }}
                                />{" "}
                                Ver Boletines Sede Los Ángeles
                              </a>
                            );
                          } else if (boletin.campus == "7") {
                            return (
                              <a
                                href="/biblioteca/ITCA"
                                className="font-bold footerSede urlBoletin text-[#d12421]"
                              >
                                <FaArrowRight
                                  className="inline"
                                  style={{ fontSize: "20px" }}
                                />{" "}
                                Ver Boletines Sede Cañete
                              </a>
                            );
                          }
                        })()}
                      </div>
                    </Card.Body>
                  </Card>
                </div>
                <br></br>
              </Col>
            ))}
        </Slider>
      </div>
    </Row>
  );
};

export default Books;

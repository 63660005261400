import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../../css/Resources.css";
import axios from "axios";
import { useEffect, useState } from "react";
import "video-react/dist/video-react.css";
import Card from "react-bootstrap/Card";
import { Media, Video } from "@vidstack/player-react";
import Banner from "../../assets/images/banner/banner_digital.png";

const Tutorials = () => {
  const [tutoriales, setTutoriales] = useState();

  const getApiDataTutoriales = async () => {
    const response = await axios
      .get(
        "https://sibucsc.cl/sibucsc/recursos/index/?id_rec=&nombre=&descripcion=&url=&categorias__categoria=31&visible=true",
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setTutoriales(response.data.results);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getApiDataTutoriales();
  }, []);

  const getVideo = (img, video) => {
    try {
      return (
        <video
          loading="visible"
          poster={require(`../../assets/${img}`)}
          src={require(`../../assets/${video}`)}
          preload="none"
          data-video="0"
          controls
        />
      );
    } catch (err) {
      return (
        <video
          loading="visible"
          poster={require(`../../assets/images/images_noticias/sin-foto.png`)}
          src={""}
          preload="none"
          data-video="0"
          controls
        />
      );
    }
  };

  return (
    <>
      <Container fluid className="web-container pt-3" id="bannerSIBUCSC">
        <Row className="col d-flex justify-content-center">
          <Col xl={4}>
            <div className="col d-flex justify-content-center">
              <img src={Banner} id="imgBanner"></img>
            </div>
          </Col>
          <Col xl={6} className="mt-md-5">
            <div>
              <div className="m-3">
                <h3 className="main-heading text-white">
                  Acceso a Recursos digitales
                </h3>
                <h6 className="text-justify text-[#C9CCD2]">
                  El Sistema de Bibliotecas pone a disposición de sus usuarios,
                  una variedad de servicios que van ligados a la mejora en su
                  desarrollo de competencias informacionales
                </h6>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="web-container">
        <Row xs={1} xxl={6} md={4} className="g-4">
          {tutoriales &&
            tutoriales.map((tut, i) => (
              <Col className="col d-flex justify-content-center" key={i}>
                <Card
                  border="#f7f7f7"
                  className="cardTutsRes"
                  style={{
                    width: "18rem",
                    height: "28rem",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <Card.Body>
                    <Media>
                      <Video loading="visible" controls preload="true">
                        {getVideo(tut.img, tut.doc)}
                      </Video>
                    </Media>
                    <Card.Title className="category">{tut.nombre}</Card.Title>
                    <Card.Text className="heading">{tut.descripcion}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
        </Row>
        <br></br>
      </Container>
    </>
  );
};
export default Tutorials;

import { useState } from "react";
import "../../css/Repo.css";
import Row from "react-bootstrap/Row";
import "../../css/HomeInfo.css";
import Modal from "react-bootstrap/Modal";
import logoSIBUCSC from "../../assets/images/home/navbar/SIBUCSC.png";
import salaTesis from "../../assets/images/home/sala_tesistas.png";
import bibInclusiva from "../../assets/images/home/biblioteca_inclusiva.png";
import prestamoInterbib from "../../assets/images/home/prestamo_interbibliotecas.png";
import Button from "react-bootstrap/Button";

const Information = () => {
  const [show, setShow] = useState(false);
  const [tesistas, setTesistas] = useState(false);
  const [pib, setPib] = useState(false);
  const [inclusiva, setInclusiva] = useState(false);

  const handleClose = () => setShow(false);
  function handleShowTesistas() {
    setShow(true);
    setTesistas(true);
    setPib(false);
    setInclusiva(false);
  }

  function handleShowPib() {
    setShow(true);
    setTesistas(false);
    setPib(true);
    setInclusiva(false);
  }

  function handleShowInclusiva() {
    setShow(true);
    setTesistas(false);
    setPib(false);
    setInclusiva(true);
  }

  function HEADER_MODAL() {
    return {
      backgroundColor: "#FFF",
      color: "black",
    };
  }
  function HEADER_TITLE() {
    return {
      color: "#646567",
      fontWeight: "999",
    };
  }
  function URL_NOTICE() {
    return {
      fontWeight: "bold",
      paddingTop: "10px",
      color: "#D6092C",
    };
  }
  return (
    <>
      <div className="col d-flex justify-content-center">
        <span className="mt-1 titleInfoInteres font-bold">
          INFORMACIÓN DE INTERÉS
        </span>
      </div>
      <Row className="m-2 grid-container d-flex mt-3">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-xl-4 d-flex flex-column mt-3">
              <div className="contentInfoInt flex-grow-1">
                <div className="col d-flex justify-content-center">
                  <img src={bibInclusiva} className="w-50"></img>
                </div>
                <div className="col d-flex justify-content-center">
                  <span className="cardTitleInfo font-bold">
                    {" "}
                    Biblioteca Inclusiva
                  </span>
                </div>
                <p className="text-justify mt-3 text-white">
                  Con el objetivo de promover la inclusión de alumnos y alumnas
                  con capacidades diferentes, el SIBUCSC, en el marco del
                  programa de inclusión estudiantil (PIE), proporciona distintos
                  recursos y equipamiento para mejorar la accesibilidad y
                  desarrollo de sus actividades académicas.
                </p>
              </div>
              <div className="footerInfoInt">
                <a style={URL_NOTICE()}>
                  <Button
                    variant="outline-danger"
                    className="btnInteres"
                    onClick={() => handleShowInclusiva()}
                  >
                    Ver más
                  </Button>
                </a>
              </div>
            </div>

            <div className="col-md-12 col-xl-4  d-flex flex-column mt-3">
              <div className="contentInfoInt flex-grow-1">
                <div className="col d-flex justify-content-center">
                  <img src={prestamoInterbib} className="w-50"></img>
                </div>
                <div className="col d-flex justify-content-center">
                  <span className="cardTitleInfo font-bold">
                    {" "}
                    Préstamo Interbibliotecario
                  </span>
                </div>
                <p className="text-justify mt-3 text-white">
                  El Servicio de Préstamo Interbibliotecario (PIB), tiene por
                  función obtener el préstamo de libros desde otras Bibliotecas
                  en convenio o que pertenezcan al Consejo de Rectores de las
                  Universidades Chilenas (CRUCH).
                </p>
              </div>
              <div className="footerInfoInt">
                <a style={URL_NOTICE()}>
                  <Button
                    variant="outline-danger"
                    className="btnInteres"
                    onClick={() => handleShowPib()}
                  >
                    Ver más
                  </Button>
                </a>
              </div>
            </div>

            <div className="col-md-12 col-xl-4 d-flex flex-column mt-3">
              <div className="contentInfoInt flex-grow-1">
                <div className="col d-flex justify-content-center">
                  <img src={salaTesis} className="w-50 imgInfoInteres"></img>
                </div>
                <div className="col d-flex justify-content-center">
                  <span className="cardTitleInfo font-bold">
                    {" "}
                    Sobre uso sala Tesistas (Campus San Andrés)
                  </span>
                </div>
                <p className="text-justify mt-3 text-white">
                  El Servicio “salas de tesistas” tiene como finalidad, apoyar
                  exclusivamente a estudiantes regulares, que se encuentren
                  realizando su Proyecto de Investigación que le otorgará el
                  Título Profesional.
                </p>
              </div>
              <div className="footerInfoInt">
                <a style={URL_NOTICE()}>
                  <Button
                    variant="outline-danger"
                    className="btnInteres"
                    onClick={() => handleShowTesistas()}
                  >
                    Ver más
                  </Button>
                </a>
              </div>
            </div>
          </div>
          <br></br>
        </div>

        <div className="App">
          <Modal show={show} size="xl" centered>
            <Modal.Header style={HEADER_MODAL()}>
              <Modal.Title className="text-center" style={HEADER_TITLE()}>
                {tesistas && <>Sala de Tesistas Campus San Andrés</>}
                {inclusiva && <>Biblioteca Inclusiva</>}
                {pib && <>Servicio de Préstamo Interbibliotecario</>}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="text-justify">
                <span className="descriptionEvent">
                  {tesistas && (
                    <>
                      <p>
                        El Servicio “salas de tesistas” tiene como finalidad,
                        apoyar exclusivamente a estudiantes regulares, que se
                        encuentren realizando su Proyecto de Investigación que
                        le otorgará el Título Profesional.
                      </p>
                      <p>
                        El SiBUCSC Sede San Andrés cuenta con 9 salas en módulos
                        de 3 horas: 09:00 a 12:00 – 12:00 a 15:00 – 15:00 a
                        18:00 horas. Cada sala tiene calefacción, pizarra,
                        escritorio y sillas para el grupo de trabajo. En el
                        lugar se pueden solicitar notebook, plumones de pizarra
                        y borrador.
                      </p>
                    </>
                  )}
                  {inclusiva && (
                    <>
                      <p>
                        Con el objetivo de promover la inclusión de alumnos y
                        alumnas con capacidades diferentes, el Sistema de
                        Bibliotecas UCSC, en el marco del programa de inclusión
                        estudiantil (PIE), proporciona distintos recursos y
                        equipamiento para mejorar la accesibilidad y desarrollo
                        de sus actividades académicas.
                      </p>
                    </>
                  )}
                  {pib && (
                    <>
                      <p>
                        El Servicio de Préstamo Interbibliotecario (PIB), tiene
                        por función obtener el préstamo de libros desde otras
                        Bibliotecas en convenio o que pertenezcan al Consejo de
                        Rectores de las Universidades Chilenas (CRUCH).
                      </p>
                      <p className="font-bold">¿Cómo acceder al servicio?</p>
                      <p>
                        Solicita este servicio a través del Jefe de Biblioteca
                        de su Sede. Es importante registrar el autor, título,
                        fecha de publicación y número de pedido del libro que
                        deseas solicitar con las entidades que tenemos convenio.
                      </p>
                      <p>
                        Todos nuestros estudiantes, académicos y funcionarios
                        registrados pueden acceder a este servicio.
                      </p>
                      <p className="font-bold">
                        Para mayor información, escríbenos a sibucsc@ucsc.cl
                      </p>
                    </>
                  )}
                </span>
              </div>
            </Modal.Body>
            <Modal.Footer style={{ float: "left" }}>
              <div className="w-100">
                <img src={logoSIBUCSC} className="h-10 bannerModal"></img>
                <Button variant="secondary float-right" onClick={handleClose}>
                  Cerrar
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      </Row>
    </>
  );
};

export default Information;

import "../../css/Footer.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import EmailIcon from "@mui/icons-material/Email";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Col, Row, Container } from "react-bootstrap";

const Footer = () => {
  return (
    <Container fluid className="main-footer">
      <Row className="flex justify-around ">
        <Col xs={12} md={4} className="justify-content-center d-flex ">
          <div>
            <p>¡Síguenos en Redes Sociales!</p>
            <ul className="list-unstyled listRss md:block ">
              <a
                href="https://www.facebook.com/bibliotecaucscsanandres"
                target="__blank"
                className="text-[#fff]"
              >
                <li className="flex justify-left">
                  <FacebookIcon fontSize="large" />{" "}
                </li>
              </a>
              <a
                href="https://www.instagram.com/sibucsc_bsa/"
                target="__blank"
                className="text-[#fff]"
              >
                <li className="flex justify-left">
                  <InstagramIcon fontSize="large" />{" "}
                </li>
              </a>
              <a
                href="https://twitter.com/SiB_UCSC"
                target="__blank"
                className="text-[#fff] ahrefTwitt"
              >
                <li className="flex justify-left">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="30"
                    width="30"
                    viewBox="0 0 512 512"
                    className="inline"
                    id="svgTwitt"
                  >
                    <path
                      fill="#fff"
                      d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
                    />
                  </svg>{" "}
                </li>
              </a>
              <a
                href="https://www.youtube.com/channel/UCdesyDR2kvfadN-XFxXqhiQ"
                target="__blank"
                className="text-[#fff]"
              >
                <li className="flex justify-left">
                  <YouTubeIcon fontSize="large" />{" "}
                </li>
              </a>
              {/* <a
                href="https://www.tiktok.com/@sibucsc"
                target="__blank"
                className="text-[#fff]"
              >
                <li className="flex justify-left">
                  <svg
                    fill={"#fff"}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 50 50"
                    width="35px"
                    height="35px"
                  >
                    <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
                  </svg>{" "}
                </li>
              </a> */}
            </ul>
          </div>
        </Col>
        <Col xs={12} md={4} className="text-center">
          <ul className="list-unstyled ">
            <h6 className="font-bold">SISTEMA DE BIBLIOTECAS - CRAI</h6>
            <h6>Alonso de Ribera #2850, Concepción, Chile</h6>
            <li>Universidad Católica de la Santísima Concepción </li>
            <li>
              Acreditada por la CNA, desde marzo de 2021 hasta marzo de 2026{" "}
            </li>
            <br />
            <li>@2024 UCSC algunos Derechos Reservados </li>
            <br />
          </ul>
        </Col>
        <Col xs={12} md={4} className="justify-content-center d-flex">
          <ul className="list-unstyled contents md:block">
            <li className="flex justify-left">
              <ContactPhoneIcon fontSize="large" />{" "}
              <span className="hidden lg:block pl-1">+56 412345570</span>{" "}
            </li>
            <li className="flex justify-left">
              <EmailIcon fontSize="large" />{" "}
              <span className="hidden lg:block pl-1"> sibucsc@ucsc.cl </span>
            </li>
            <li className="flex justify-left">
              <a href="/direccion" className="text-[#fff]">
                <ManageSearchIcon fontSize="large" />
              </a>
              <span className="hidden lg:block pl-1">
                <a href="/direccion" className="text-[#fff]">
                  Quiénes somos
                </a>
              </span>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;

import React, { useState, useEffect } from "react";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import "../../css/Databases.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { FaSearch, FaInfoCircle, FaAngleRight } from "react-icons/fa";
import Select from "react-select";
import Banner from "../../assets/images/banner/banner_digital.png";
import "primereact/resources/themes/nova/theme.css";
import "primereact/resources/primereact.min.css";
import { CFormCheck } from "@coreui/react";
import Button from "react-bootstrap/Button";

export default function BasicFilterDemo() {
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    category: { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  const [loading, setLoading] = useState(true);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [dataBases, setDataBases] = useState();
  const [listCategory, setListCategory] = useState();
  const [countDB, setCountDB] = useState(0);
  const [filterValue, setFilterValue] = useState("");
  const [disabledAlf, setDisabledAlf] = useState(false);

  const [filtroCategory, setFiltroCategory] = useState(true);

  const alphabet = [
    "Todas",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  const catPrincipales = [
    "Libros Electrónicos",
    "Revistas Electrónicas",
    "Referenciales",
    "Base de Datos",
  ];

  const getApiDataBases = async () => {
    const response = await axios
      .get(
        "https://sibucsc.cl/sibucsc/recursos/index/bases/?ordering=-pk&visible=true",
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setDataBases(response.data.results);
        setCountDB(response.data.count);
      })
      .catch((err) => {});
  };

  const getApiCategorias = async () => {
    const response = await axios
      .get("https://sibucsc.cl/sibucsc/recursos/tipos/cat_bases/", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        let resc = { label: "Todas las Categorías", value: "Todas" };
        let res = response.data.map((v) =>
          Object.assign({}, { label: v.nombre, value: v.id_cat })
        );
        res.sort(function (a, b) {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });

        let listCategory = [resc, ...res];
        setListCategory(listCategory.sort());
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getApiDataBases();
    getApiCategorias();
    setLoading(false);
  }, []);

  //FILTRO GLOBAL POR NOMBRE
  const applyGlobalFilter = () => {
    setGlobalFilterValue(filterValue);
    if (filterValue !== "" && filtroCategory === true) {
      setColorCheck("");
      setDisabledAlf(true);
      setFilters({
        global: { value: filterValue, matchMode: FilterMatchMode.CONTAINS },
        category: { value: null, matchMode: FilterMatchMode.EQUALS },
      });
    } else if (filterValue === "" && filtroCategory === true) {
      setDisabledAlf(false);
      setColorCheck("");
      setFilters({
        global: { value: "", matchMode: FilterMatchMode.CONTAINS },
        category: { value: null, matchMode: FilterMatchMode.EQUALS },
      });
    }
  };

  /* FILTRO ALFABETICO */
  const onAlphabeticFilterChange = (letter) => {
    if (letter == "Todas") {
      setFilters({
        global: { value: "", matchMode: FilterMatchMode.STARTS_WITH },
        category: { value: null, matchMode: FilterMatchMode.EQUALS },
      });
    } else {
      const value = letter;
      setFilters({
        global: { value: value, matchMode: FilterMatchMode.STARTS_WITH },
        category: { value: null, matchMode: FilterMatchMode.EQUALS },
      });
    }
  };

  /* FILTRO POR  CATEGORIA */
  const onGlobalFilterChangeCat = (e) => {
    const value = e.value;
    if (e.value !== "Todas") {
      const response = axios
        .get(
          "https://sibucsc.cl/sibucsc/recursos/index/bases/?ordering=-pk&categorias__categoria=" +
            value +
            "&visible=true",
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          setDataBases(response.data.results);
        })
        .catch((err) => {});
    } else {
      const response = axios
        .get(
          "https://sibucsc.cl/sibucsc/recursos/index/bases/?visible=true&ordering=-pk",
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          setDataBases(response.data.results);
        })
        .catch((err) => {});
    }
  };

  const onChangeColor = (letter) => {
    setColorCheck(letter);
  };

  const [colorCheck, setColorCheck] = useState("transparent");
  const defaultValue = {
    value: "Todas las Categorías",
    label: "Todas las Categorías",
  };

  const renderHeader = () => {
    return (
      <div>
        <p className="pt-3 font-bold ms-5">
          {countDB} recursos digitales disponibles
        </p>
        <div className="d-inline-flex justify-content-end p-2 w-100">
          <span className="d-inline-flex justify-content-end p-2 w-100">
            <Container>
              <Row className="col d-flex justify-content-center">
                <Col xs={12} md={6}>
                  {" "}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <InputText
                      value={filterValue}
                      onChange={(e) => setFilterValue(e.target.value)}
                      placeholder="Encuentre su recurso digital ingresando el nombre aquí"
                      className="form-control"
                      style={{ width: "100%" }}
                    />
                    <Button
                      onClick={applyGlobalFilter}
                      className="ms-3"
                      variant="secondary"
                    >
                      Buscar
                    </Button>
                  </div>
                  <div className="mt-xl-4 mt-3">
                    <Select
                      options={listCategory}
                      defaultValue={defaultValue}
                      onChange={onGlobalFilterChangeCat}
                      className="w-100"
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </span>
        </div>
        <div role="group" id="divAlphabet">
          <div
            className="col-xl d-xl-flex justify-content-center mt-3"
            translate="no"
          >
            {alphabet.map((letter, index) => (
              <CFormCheck
                type="radio"
                key={index}
                button={{
                  color: letter === colorCheck ? "danger" : "transparent",
                }}
                name="options"
                id={`options-${index}`}
                autoComplete="off"
                label={letter}
                onClick={() => onChangeColor(letter)}
                onChange={() => onAlphabeticFilterChange(letter, index)}
                disabled={disabledAlf}
              />
            ))}
          </div>
        </div>
      </div>
    );
  };

  const DBBodyImg = (rowData, i) => {
    try {
      return (
        <div className="flex align-items-center gap-2">
          <img
            alt="flag"
            src={require(`../../assets/${rowData.img}`)}
            style={{ width: "110px" }}
          />
        </div>
      );
    } catch (err) {
      return (
        <div className="flex align-items-center gap-2">
          <img
            alt="flag"
            src={require(`../../assets/images/resources/sin-foto.png`)}
            style={{ width: "110px" }}
          />
        </div>
      );
    }
  };

  const DBUrlBodyAccess = (rowData) => {
    return (
      <div className="flex align-items-center gap-2">
        {rowData.url === "file" ? (
          <a
            alt="flag"
            href={require(`../../assets/${rowData.doc}`)}
            target="_blank"
            className="enlace text-white"
            style={{ width: "110px" }}
          >
            <button className="bg-red p-3 m-3 rounded-md">
              {" "}
              <FaSearch />
            </button>
          </a>
        ) : (
          <a href={rowData.url} className="enlace text-white" target="_blank">
            <button className="bg-red p-3 m-3 rounded-md">
              <FaSearch />
            </button>
          </a>
        )}
      </div>
    );
  };

  const header = renderHeader();

  return (
    <>
      <Container fluid className="web-container pt-3" id="bannerSIBUCSC">
        <Row className="col d-flex justify-content-center">
          <Col xl={4}>
            <div className="col d-flex justify-content-center">
              <img src={Banner} id="imgBanner"></img>
            </div>
          </Col>
          <Col xl={6} className="mt-md-5">
            <div>
              <div className="m-3">
                <h3 className="main-heading text-white">
                  Nuestra Biblioteca Digital
                </h3>
                <h6 className="text-justify text-[#C9CCD2]">
                  Es una colección de objetos digitales, tales como libros,
                  revistas, audios, videos, imágenes, y audiolibros que existen
                  en formato digital y se ofrecen a la comunidad académica.
                  Proporciona un acceso rápido desde cualquier lugar y horario,
                  lo único que se necesita es una computadora o celular con
                  acceso a Internet.
                </h6>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <div className="col d-flex justify-content-center">
          <span className="font-bold titleInfoBD">
            Buscador de recursos digitales
          </span>
        </div>
        {/* MAS ACCEDIDOS */}
        <div className="mt-5">
          <FaInfoCircle className="inline text-[#d12421]" size={30} />
          <span className="font-bold titleMasAccedidos ms-1">
            Visita nuestros recursos más utilizados
          </span>
          <ul className="listMasAccedidos mt-4">
            <li className="itemMasAccedidos">
              <FaAngleRight className="inline text-[#646567]" />
              <span className="ms-1">
                {" "}
                <a
                  href="https://go.openathens.net/redirector/ucsc.cl?url=https%3A%2F%2Faccessmedicina.mhmedical.com%2Fbooks.aspx%3Fview%3Dlibrary"
                  target="_blank"
                  className="enlace"
                >
                  Access Medicina
                </a>
              </span>
            </li>
            <li className="itemMasAccedidos">
              <FaAngleRight className="inline text-[#646567]" />
              <span className="ms-1">
                {" "}
                <a
                  href="https://go.openathens.net/redirector/ucsc.cl?url=https%3A%2F%2Fwww.digitaliapublishing.com"
                  target="_blank"
                  className="enlace"
                >
                  Digitalia
                </a>
              </span>
            </li>
            <li className="itemMasAccedidos">
              <FaAngleRight className="inline text-[#646567]" />
              <span className="ms-1">
                {" "}
                <a
                  href="https://elibro.net/es/lc/sibucsc/inicio"
                  target="_blank"
                  className="enlace"
                >
                  E-Libro
                </a>
              </span>
            </li>
            <li className="itemMasAccedidos">
              <FaAngleRight className="inline text-[#646567]" />
              <span className="ms-1">
                {" "}
                <a
                  href="https://search.ebscohost.com/login.aspx?authtype=sso&custid=s6620531&groupid=main&profile=ehost"
                  target="_blank"
                  className="enlace"
                >
                  EBSCO Host
                </a>
              </span>
            </li>
            <li className="itemMasAccedidos">
              <FaAngleRight className="inline text-[#646567]" />
              <span className="ms-1">
                {" "}
                <a
                  href="https://go.openathens.net/redirector/ucsc.cl?url=https%3A%2F%2Fwww.scopus.com%2F"
                  target="_blank"
                  className="enlace"
                >
                  Scopus
                </a>
              </span>
            </li>
            <li className="itemMasAccedidos">
              <FaAngleRight className="inline text-[#646567]" />
              <span className="ms-1">
                {" "}
                <a
                  href="https://go.openathens.net/redirector/ucsc.cl?url=https%3A%2F%2Fuptodate.com"
                  target="_blank"
                  className="enlace"
                >
                  UpToDate
                </a>
              </span>
            </li>

            <li className="itemMasAccedidos">
              <FaAngleRight className="inline text-[#646567]" />
              <span className="ms-1">
                <a
                  href="https://go.openathens.net/redirector/ucsc.cl?url=https%3A%2F%2Fapp.vlex.com"
                  target="_blank"
                  className="enlace"
                >
                  vLex
                </a>
              </span>
            </li>
            <li className="itemMasAccedidos">
              <FaAngleRight className="inline text-[#646567]" />
              <span className="ms-1">
                {" "}
                <a
                  href="https://go.openathens.net/redirector/ucsc.cl?url=http://www.webofknowledge.com/?auth=ShibbolethIdPForm&entityID=https%3A%2F%2Fidp.ucsc.cl%2Fentity&target=https%253A%252F%252Fwww.webofknowledge.com%252F%253FDestApp%253DWOS&ShibFederation=OpenAthensFed"
                  target="_blank"
                  className="enlace"
                >
                  Web of Science
                </a>
              </span>
            </li>
          </ul>
        </div>
        <Row>
          <Col>
            <div className="filter-search-container mt-5">
              <DataTable
                value={dataBases}
                paginator
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                rows={15}
                rowsPerPageOptions={[5, 10, 25, 50]}
                filters={filters}
                filterDisplay="columns"
                loading={loading}
                globalFilterFields={["nombre"]}
                globalFilter={globalFilterValue}
                header={header}
                showGridlines
                stripedRows
                emptyMessage="No se han encontrado bases de datos."
                style={{ width: "100%", minHeight: "500px" }}
                currentPageReportTemplate="{first} a {last} de {totalRecords}"
              >
                <Column body={DBBodyImg} header="#" className="pr-5" />
                <Column field="nombre" header="Nombre" />
                <Column header="Ingresar" body={DBUrlBodyAccess} />
              </DataTable>
            </div>
            <br></br>
          </Col>
        </Row>
      </Container>
    </>
  );
}

import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "../../css/Resources.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Banner from "./Banner";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import IAcademic from "./IAcademic";
import GB from "./Manager";
import NE from "./Regulations";
import "../../css/Investigation.css";

const Support = () => {
  return (
    <>
      <Banner />
      <Container className="web-container pt-3">
        <div className="col d-flex justify-content-center">
          <span className="font-bold titleInfoInv">
            Herramientas de Apoyo
          </span>
        </div>
        <Row>
          <Col>
            <Tabs
              defaultActiveKey="IA"
              id="tabHA"
              className="navT mb-3 mt-5"
              fill
            >
              <Tab eventKey="IA" title="Integridad Académica">
                <IAcademic />
              </Tab>
              <Tab eventKey="GB" title="Gestores Bibliográficos">
                <GB />
              </Tab>
              <Tab eventKey="NE" title="Normas de Estilo">
                <NE />
              </Tab>
            </Tabs>
          </Col>
        </Row>
        <br></br>
        <br></br>
      </Container>
    </>
  );
};
export default Support;

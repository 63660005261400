import { useEffect, useState } from "react";
import List from "@mui/material/List";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListItem from "@mui/material/ListItem";
import Collapse from "@mui/material/Collapse";
import { styled } from "@mui/material/styles";

const StyledList = styled(List)`
  padding: 0;
`;

const StyledListItem = styled(ListItem)`
  --tw-text-opacity: 1;
  :hover {
    color: #fff;
    --tw-bg-opacity: 1;
  }
`;

const CollapseButton = ({ item, i }) => {
  const path = window.location.pathname;

  useEffect(() => {
    if (
      path === "/biblioteca/BSA" ||
      path === "/biblioteca/BSD" ||
      path === "/biblioteca/BCH" ||
      path === "/biblioteca/ITTA" ||
      path === "/biblioteca/ITLA" ||
      path === "/biblioteca/ITCH" ||
      path === "/biblioteca/ITCA" ||
      path === "/direccion"
    ) {
      const element = document.getElementById("Bibliotecas-movil");
      element.classList.add("activeMovil");
    } else if (
      path === "/inv/apoyo" ||
      path === "/inv/publicacion" ||
      path === "/inv/visibilidad" ||
      path === "/inv/enlaces"
    ) {
      const element = document.getElementById("Apoyo a la Investigación-movil");
      element.classList.add("activeMovil");
    } else if (
      path === "/ayuda" ||
      path === "/ayuda-digital" ||
      path === "/consultas"
    ) {
      const element = document.getElementById("Ayuda-movil");
      element.classList.add("activeMovil");
    } else if (path === "/stalleres" || path === "/sarticulos") {
      const element = document.getElementById("Solicitudes-movil");
      element.classList.add("activeMovil");
    }
    return () => {};
  });

  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  return (
    <StyledList key={i}>
      <ListItem
        className="justify-content-between cursor-pointer"
        onClick={handleClick}
      >
        <span id={item.name + "-movil"} className="text-[#646567]">
          {item.name}
        </span>
        {open ? (
          <ExpandLess className="!fill-gray-300"></ExpandLess>
        ) : (
          <ExpandMore className="!fill-gray-300"></ExpandMore>
        )}
      </ListItem>
      <Collapse in={open}>
        {item.subList.map((el, i) => (
          <StyledListItem
            sx={{ pl: 4 }}
            className="font-medium cursor-pointer text-sm"
            key={i}
          >
            <a href={el.href} className="text-gray-500">
              {el.name}
            </a>
          </StyledListItem>
        ))}
      </Collapse>
    </StyledList>
  );
};

export default CollapseButton;

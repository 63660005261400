import "../../css/Repo.css";
import desktopJournal from "../../assets/images/home/revistas_academicas_01.png";
import { Tooltip as ReactTooltip } from "react-tooltip";

const Journal = () => {
  return (
    <div className="divRepo" data-tooltip-id="my-tooltip-journal">
      <a href="https://revistas.ucsc.cl" target="_blank" className="hrefRepo">
        <h4 className="text-center titleRepo font-bold text-center text-[#fff]">
          Revistas Académicas
        </h4>
        <div className="col d-flex justify-content-center">
          <img src={desktopJournal} alt="" className="imgRepo journal mt-2" />
        </div>
        <div id="descRepos">
          <ReactTooltip
            id="my-tooltip-journal"
            className="tooltipRepos"
            place="bottom"
            variant="info"
            content="El portal de Revistas Académicas permite un acceso abierto a los
            artículos científicos publicados en cada revista. Encontrarás
            información de las convocatorias, plazos de envío de artículos de
            investigación para su revisión y publicación."
          />
        </div>
      </a>
    </div>
  );
};

export default Journal;

import "../../css/Announc.css";
import Row from "react-bootstrap/Row";
import { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import logoSIBUCSC from "../../assets/images/home/navbar/SIBUCSC.png";
import { useMatchMedia } from "../../useMatchMedia";

const Announ = () => {
  const isDesktopResolution = useMatchMedia("(max-width:1800px)", true);
  const [noticias, setNoticias] = useState();
  const [filteredList, setFilteredList] = useState();
  const [filteredLinks, setFilteredLinks] = useState();
  const [positionOwl, setPositionOwl] = useState(0);
  const [filterEvent, setFilterEvent] = useState([]);

  const [show, setShow] = useState(false);

  const getApiData = async () => {
    const response = await axios
      .get("https://sibucsc.cl/sibucsc/noticias/index/?limit=10&tipo=1", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setNoticias(response.data.results);
        setFilteredList(response.data.results);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getApiData();
  }, []);

  const handleClose = () => setShow(false);
  function handleShow(i) {
    setFilterEvent(filteredList[i]);
    setFilteredLinks(filteredList[i].enlaces);
    setPositionOwl(i);
    setShow(true);
  }

  const options = {
    margin: 50,
    responsiveClass: true,
    nav: true,
    dots: false,
    loop: false,
    autoplay: true,
    smartSpeed: 1000,
    startPosition: positionOwl,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };
  function TITLE_NOTICE() {
    return {
      fontWeight: "bold",
      with: "auto",
      fontSize: "17px",
    };
  }
  function URL_NOTICE() {
    return {
      fontWeight: "bold",
      paddingTop: "10px",
      color: "#D6092C",
    };
  }

  function HEADER_MODAL() {
    return {
      backgroundColor: "#FFF",
      color: "black",
    };
  }

  function HEADER_TITLE() {
    return {
      color: "#646567",
      fontWeight: "999",
    };
  }

  const getImg = (img) => {
    try {
      return (
        <img
          alt=""
          className="imgAnnounc"
          src={require(`../../assets/images/${img}`)}
        />
      );
    } catch (err) {
      return (
        <img
          alt=""
          className="imgAnnounc"
          src={require(`../../assets/images/images_noticias/sin-foto.png`)}
        />
      );
    }
  };

  const truncateParagraph = (text, maxLength) => {
    if (isDesktopResolution) {
      maxLength = 60;
    } else {
      maxLength = 83;
    }
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + "...";
  };

  return (
    <Row className="m-4">
      <div className="col d-flex justify-content-center">
        <span className="titleAnnounc font-bold">&nbsp;NOTICIAS</span>
      </div>
      {noticias && (
        <OwlCarousel className="owl-carousel owl-theme mt-md-5" {...options}>
          {noticias.map((noticia, i) => {
            return (
              <div className="divAnnounc h-100" key={i}>
                {getImg(noticia.imagen)}
                <p className="mt-3 text-[#646567]" style={TITLE_NOTICE()}>
                  {truncateParagraph(noticia.titulo)}
                </p>
                <a style={URL_NOTICE()}>
                  <Button
                    variant="outline-danger"
                    className="btnAnnounc"
                    onClick={() => handleShow(i)}
                  >
                    Ver más
                  </Button>
                </a>
              </div>
            );
          })}
        </OwlCarousel>
      )}
      <Modal show={show} size="xl" centered>
        <Modal.Header style={HEADER_MODAL()}>
          <Modal.Title className="text-center" style={HEADER_TITLE()}>
            {filterEvent.titulo}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-justify mt-2">
            <span className="descriptionEvent"> {filterEvent.descripcion}</span>
          </div>
          {filteredLinks &&
            filteredLinks.map((link, i) => (
              <div className="mt-1" key={i}>
                <p>
                  {" "}
                  {link.link.includes("docs/news/") ? (
                    <p className="mt-3">
                      {link.link && (
                        <a
                          className="enlace font-bold"
                          href={require(`../../assets/${link.link}`)}
                          target="_blank"
                        >
                          {link.descripcion}
                        </a>
                      )}
                    </p>
                  ) : (
                    <p className="mt-3">
                      {link.link && (
                        <a href={link.link} className="enlace" target="__blank">
                          {link.descripcion}
                        </a>
                      )}
                    </p>
                  )}
                </p>
              </div>
            ))}
          <span className="mt-1 d-flex justify-content-end font-bold">
            {filterEvent.fecha_publicacion}
          </span>
        </Modal.Body>
        <Modal.Footer style={{ float: "left" }}>
          <div className="w-100">
            <img src={logoSIBUCSC} className="h-10 bannerModal"></img>
            <Button variant="secondary float-right" onClick={handleClose}>
              Cerrar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </Row>
  );
};

export default Announ;

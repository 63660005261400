import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "../../css/Investigation.css";

const Plagiarism = () => {
  const tipologias = [
    {
      id: 1,
      item: "Preservar caracteres (copy-paste)",
    },
    {
      id: 2,
      item: "Conservar sintaxis: Cosmética académica, Sustitución de sinónimos.",
    },
    {
      id: 3,
      item: "Conservar semántica: Traducción, Falsa paráfrasis.",
    },
    {
      id: 4,
      item: "Conservar Ideas: Usar conceptos.",
    },
    {
      id: 5,
      item: "Ghostwriting: Escritura fantasma en español) es un término usado para describir un texto escrito por una persona pero publicado bajo el nombre de otro.",
    },
  ];

  const acto = [
    {
      id: 1,
      item: "Usas frases o ideas de otros autores y no le das el crédito correspondiente a través de una cita.",
    },
    {
      id: 2,
      item: "Presentas un trabajo de investigación o tarea de otra persona como si fuese tuyo.",
    },
    {
      id: 3,
      item: "Cuando insertas citas con datos incorrectos que no pueden ser encontrados.",
    },
    {
      id: 4,
      item: "Incorrección de citas.",
    },
    {
      id: 5,
      item: "Parafraseando ideas del autor y no citarlas. (Cambiar las palabras usadas por el autor con sinónimos)",
    },
  ];

  return (
    <>
      <Container>
        <Row className="mt-5">
          <Col>
            <div className="invDev mt-1">
              <div className="inner">
                <p className="title">¿Qué es el plagio?</p>
                El plagio es cualquier acto intencional o inconsciente en donde,
                de acuerdo a MerriamWebster (2015): se presenta un trabajo
                copiado de otro autor como si fuera nuevo y original, se
                utilizan las ideas de otra persona como si fueran propias, o no
                se citan fuentes de acuerdo a convenciones académicas. En otras
                palabras, el plagio es un acto de fraude que implica robo y
                mentira.
              </div>
            </div>
            <div className="invDev mt-1">
              <div className="inner">
                <p className="title">Tipologías de Plagio</p>
                <ul className="features">
                  {tipologias.map((tip) => (
                    <li key={tip.id}>
                      <span className="">
                        <svg
                          width="30px"
                          height="30px"
                          viewBox="0 0 100 100"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          role="img"
                          class="iconify iconify--gis"
                          preserveAspectRatio="xMidYMid meet"
                        >
                          <path
                            d="M50 37.45c-6.89 0-12.55 5.66-12.55 12.549c0 6.89 5.66 12.55 12.55 12.55c6.655 0 12.112-5.294 12.48-11.862a3.5 3.5 0 0 0 .07-.688a3.5 3.5 0 0 0-.07-.691C62.11 42.74 56.653 37.45 50 37.45zm0 7c3.107 0 5.55 2.442 5.55 5.549s-2.443 5.55-5.55 5.55c-3.107 0-5.55-2.443-5.55-5.55c0-3.107 2.443-5.549 5.55-5.549z"
                            fill="#d12421"
                          ></path>
                        </svg>
                      </span>
                      <span>{tip.item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="invDev">
              <div className="inner">
                <p className="title">
                  ¿Cómo sé si estoy cometiendo plagio en mi investigación?
                </p>
                <ul className="features">
                  {acto.map((ac) => (
                    <li key={ac.id}>
                      <span>
                        <svg
                          width="30px"
                          height="30px"
                          viewBox="0 0 100 100"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          role="img"
                          class="iconify iconify--gis"
                          preserveAspectRatio="xMidYMid meet"
                        >
                          <path
                            d="M50 37.45c-6.89 0-12.55 5.66-12.55 12.549c0 6.89 5.66 12.55 12.55 12.55c6.655 0 12.112-5.294 12.48-11.862a3.5 3.5 0 0 0 .07-.688a3.5 3.5 0 0 0-.07-.691C62.11 42.74 56.653 37.45 50 37.45zm0 7c3.107 0 5.55 2.442 5.55 5.549s-2.443 5.55-5.55 5.55c-3.107 0-5.55-2.443-5.55-5.55c0-3.107 2.443-5.549 5.55-5.549z"
                            fill="#d12421"
                          ></path>
                        </svg>
                      </span>
                      <span>{ac.item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="row-lib pt-2">
          <Col>
            <div className="cardCons mt-3">
              <div className="header">
                <span className="iconPlagio">
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clipRule="evenodd"
                      d="M18 3a1 1 0 00-1.447-.894L8.763 6H5a3 3 0 000 6h.28l1.771 5.316A1 1 0 008 18h1a1 1 0 001-1v-4.382l6.553 3.276A1 1 0 0018 15V3z"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                </span>
                <p className="titleInv">¿Cómo detectar el plagio?</p>
              </div>
              <p className="infoP text-justify">
                Existen plataformas en línea tanto gratuitas como de pago útiles
                servicios en línea que permiten revisar, y encontrar similitudes
                con otros documentos, todo tipo de textos también links, que
                pueden ser usados por docentes, investigadores y estudiantes.
              </p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="cardCons mt-3">
              <div className="header">
                <span className="iconPlagio">
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clipRule="evenodd"
                      d="M18 3a1 1 0 00-1.447-.894L8.763 6H5a3 3 0 000 6h.28l1.771 5.316A1 1 0 008 18h1a1 1 0 001-1v-4.382l6.553 3.276A1 1 0 0018 15V3z"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                </span>
                <p className="titleInv">
                  Consecuencias del plagio en la investigación
                </p>
              </div>
              <p className="infoP text-justify">
                Según el Artículo 79 Bis de la ley N° 17.336 de Propiedad
                Intelectual-República de Chile. “Falsificar obras, o editarlas,
                reproducirlas o distribuirlas ostentando falsamente el nombre
                del editor autorizado, suprimir o cambiar el nombre del autor o
                el título de la obra, o alterar maliciosamente su texto. La
                sanción de esta conducta será la pena de reclusión menor en su
                grado mínimo y multa de 10 a 1.000 UTM”.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <br></br>
    </>
  );
};
export default Plagiarism;

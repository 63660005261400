import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "../../css/Resources.css";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import TallerVancouver from "../../assets/docs/rules/taller-4-vancouver-oficial.pdf";
import ISO690 from "../../assets/docs/rules/ISO690_2.pdf";
import TallerAPA from "../../assets/docs/rules/taller-4-apa-7edición.pdf";
import Badge from "react-bootstrap/Badge";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { CWidgetStatsF, CLink } from "@coreui/react";
import { FaBullhorn } from "react-icons/fa";
import logoSIBUCSC from "../../assets/images/home/navbar/SIBUCSC.png";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const Regulations = () => {
  const [show, setShow] = useState(false);
  const [enlaces, setEnlaces] = useState();

  function handleShow() {
    setShow(true);
  }

  const handleClose = () => setShow(false);

  const getApiDataEnlaces = async () => {
    const response = await axios
      .get(
        "https://sibucsc.cl/sibucsc/recursos/index/?categorias__categoria=28",
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setEnlaces(response.data.results);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getApiDataEnlaces();
  }, []);

  const renderTooltip = (descripcion) => (
    <Tooltip id="button-tooltip">{descripcion}</Tooltip>
  );

  const getImg = (img) => {
    try {
      return <img src={require(`../../assets/${img}`)} className="h-10" />;
    } catch (err) {
      return (
        <img
          src={require(`../../assets/images/resources/sin-foto.png`)}
          className="h-10"
        />
      );
    }
  };

  return (
    <>
      <Container>
        <Row className="mt-5">
          <Col lg={6} md={6} xs={12}>
            <h3 className="text-[#646567] font-bold mt-4">Norma Vancouver</h3>
            <ListGroup as="ol" numbered className="mt-3 invDev">
              <ListGroup.Item as="li" className="d-flex align-items-start">
                <Card.Link
                  href={TallerVancouver}
                  target="__blank"
                  className="text-[#DC3545] font-bold"
                >
                  &emsp;Uso adecuado y ético de la información: Norma Vancouver
                  - Taller (PDF)
                </Card.Link>
              </ListGroup.Item>
              <ListGroup.Item as="li" className="d-flex align-items-start">
                <Card.Link
                  href="https://www.icmje.org"
                  target="__blank"
                  className="text-[#DC3545] font-bold"
                >
                  &emsp;Comité Internacional de Editores de Revistas Médicas
                  (ICMJE)
                </Card.Link>
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col lg={6} md={6} xs={12}>
            <h3 className="text-[#646567] font-bold mt-4">
              Norma Internacional ISO 690:2010
            </h3>
            <ListGroup as="ol" numbered className="mt-3 invDev">
              <ListGroup.Item as="li" className="d-flex align-items-start">
                <Card.Link
                  href={ISO690}
                  target="__blank"
                  className="text-[#DC3545] font-bold"
                >
                  &emsp;Manual ISO 690:2010 (PDF)
                </Card.Link>
              </ListGroup.Item>
              <ListGroup.Item as="li" className="d-flex align-items-start">
                <Card.Link
                  href="https://www.iso.org/standard/43320.html"
                  target="__blank"
                  className="text-[#DC3545] font-bold"
                >
                  &emsp;Sitio Oficial ISO - Norma ISO 690:2010
                </Card.Link>
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} xs={12}>
            <h3 className="text-[#646567] font-bold mt-5">
              Herramientas para citar en línea
            </h3>
            <ListGroup as="ol" numbered className="mt-3 invDev">
              {enlaces &&
                enlaces.map((enlace, i) => (
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                    key={i}
                  >
                    <div className="ms-2 me-auto">
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip(enlace.descripcion)}
                      >
                        <div className="fw-bold">{enlace.nombre}</div>
                      </OverlayTrigger>
                    </div>
                    {enlace.img.length > 0 && (
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip(enlace.descripcion)}
                      >
                        <Badge
                          bg="bg-transparent"
                          className="badgeResources d-flex justify-content-center bg-[#D6625F]"
                        >
                          <a href={enlace.url} target="_blank">
                            {getImg(enlace.img)}
                          </a>
                        </Badge>
                      </OverlayTrigger>
                    )}
                  </ListGroup.Item>
                ))}{" "}
            </ListGroup>
          </Col>
          <Col lg={6} md={6} xs={12}>
            <h3 className="text-[#646567] font-bold mt-5">Norma APA</h3>
            <ListGroup as="ol" numbered className="mt-3 invDev">
              <ListGroup.Item as="li" className="d-flex align-items-start">
                <Card.Link
                  href={TallerAPA}
                  target="__blank"
                  className="text-[#DC3545] font-bold"
                >
                  &emsp;Uso adecuado y ético de la información: Normas APA -
                  Taller (PDF)
                </Card.Link>
              </ListGroup.Item>
              <ListGroup.Item as="li" className="d-flex align-items-start">
                <Card.Link
                  href="http://www.apastyle.org"
                  target="__blank"
                  className="text-[#DC3545] font-bold"
                >
                  &emsp;Sitio Web Oficial - APA Style
                </Card.Link>
              </ListGroup.Item>
            </ListGroup>
            <Row>
              <Col className="mt-5">
                <CWidgetStatsF
                  className="mb-3 font-bold"
                  color="danger"
                  footer={
                    <CLink
                      className="font-weight-bold font-xs text-[#d12421]"
                      style={{ cursor: "pointer" }}
                      href="https://www.youtube.com/playlist?list=PLhFIHOO1pluIoeeGdWVtUaeT8vv7OAQpw
                  "
                      rel="noopener norefferer"
                      target="_blank"
                    >
                      Ver curso
                    </CLink>
                  }
                  icon={<FaBullhorn height={24} />}
                  title="Curso práctico de normas APA 7ma edición"
                />
              </Col>
            </Row>
            <Row>
              <Col className="mt-1">
                <CWidgetStatsF
                  className="mb-3 font-bold"
                  color="danger"
                  footer={
                    <CLink
                      className="font-weight-bold font-xs text-[#d12421]"
                      style={{ cursor: "pointer" }}
                      href="https://www.youtube.com/watch?v=CVAiAZbqdF4"
                      rel="noopener norefferer"
                      target="_blank"
                    >
                      Ver curso
                    </CLink>
                  }
                  icon={<FaBullhorn height={24} />}
                  title='Capacitación "Estilo de Citación APA 7ma edición"'
                />
              </Col>
            </Row>
            <Row>
              <Col className="mt-1">
                <CWidgetStatsF
                  className="mb-3 font-bold"
                  color="danger"
                  footer={
                    <CLink
                      className="font-weight-bold font-xs text-[#d12421]"
                      style={{ cursor: "pointer" }}
                      rel="noopener norefferer"
                      onClick={() => handleShow()}
                    >
                      Ver información
                    </CLink>
                  }
                  icon={<FaBullhorn height={24} />}
                  title="¿Citar o no Citar un trabajo de Investigación?"
                />
              </Col>
            </Row>
            <Modal show={show} size="xl" centered>
              <Modal.Header>
                <Modal.Title className="text-center">
                  ¿Citar o no citar un trabajo de Investigación?
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="text-justify col d-flex justify-content-center">
                  <img
                    src={require(`../../assets/images/investigation/citar-investigacion.png`)}
                    className="mt-3"
                    style={{ width: "800px", height: "500px" }}
                  />
                </div>
              </Modal.Body>
              <Modal.Footer style={{ float: "left" }}>
                <div className="w-100">
                  <img src={logoSIBUCSC} className="h-10 bannerModal"></img>
                  <Button variant="secondary float-right" onClick={handleClose}>
                    Cerrar
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
          </Col>
        </Row>
        <br></br>
      </Container>
    </>
  );
};
export default Regulations;

const NotFound = () => {
  return (
    <><div className="mt-5">
          <div className="col d-flex justify-content-center mt-5">
              <h1 className="size404">404</h1>
          </div>
          <div className="col d-flex justify-content-center mt-1">
              {" "}
              <h3>La URL especificada no existe</h3>
          </div>
          <div className="col d-flex justify-content-center mt-4">
              <h5 className="text-justify m-3">
                  Estimado(a), el sistema no puede encontrar la dirección que has
                  ingresado. Por favor verifica que esté escrita correctamente e intenta
                  nuevamente.
              </h5>
          </div>
          <p className="col d-flex justify-content-center mt-4">
              <button className="bg-[#d12421] hover:bg-red-700 text-lg  font-bold py-2 px-4 rounded-full mt-4">
                  <a href="/" className="text-[#fff]">
                      IR AL INICIO
                  </a>
              </button>
          </p>
      </div><div className="padding404"></div></>
  );
};

export default NotFound;

import React from "react";
import "./App.css";
import Navbar from "./Components/LayoutComponents/Navbar";
import Footer from "./Components/LayoutComponents/Footer";
import { Home } from "./Components/Home";
import DetalleBiblioteca from "./Components/LibraryComponents/LibraryDetail";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Digital from "./Components/DigitalComponents/Databases";
import Ayuda from "./Components/HelpComponents/Questions";
import ConsultasBib from "./Components/HelpComponents/Bibliotecary.js";
import TutorialesRecs from "./Components/HelpComponents/Resources.js";
import Direccion from "./Components/LibraryComponents/Management";

import HA from "./Components/InvestigationComponents/HA.js";
import HP from "./Components/InvestigationComponents/HP.js";
import HV from "./Components/InvestigationComponents/HV.js";
import EI from "./Components/InvestigationComponents/Ei.js";

import STalleres from "./Components/RequestComponents/Workshop.js";
import SArticulos from "./Components/RequestComponents/Articles.js";
import NotFound from "./Components/404.js";

const App = () => {

  return (
    <>
      <Navbar />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />
          <Route
            path="/biblioteca/:abreviatura"
            element={<DetalleBiblioteca />}
          />
          <Route path="/direccion" element={<Direccion />} />
          <Route path="/digital" element={<Digital />} />
          <Route path="/ayuda" element={<Ayuda />} />
          <Route path="/consultas" element={<ConsultasBib />} />
          <Route path="/ayuda-digital" element={<TutorialesRecs />} />

          <Route path="/stalleres" element={<STalleres />} />
          <Route path="/sarticulos" element={<SArticulos />} />

          {/* INVESTIGACION */}
          <Route path="/inv/apoyo" element={<HA />} />
          <Route path="/inv/publicacion" element={<HP />} />
          <Route path="/inv/visibilidad" element={<HV />} />
          <Route path="/inv/enlaces" element={<EI />} />
          {/*  CIERRE INVESTIGACION */}
        </Routes>
      </BrowserRouter>
      <Footer />
    </>
  );
};

export default App;

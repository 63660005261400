import { useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "../../css/Investigation.css";
import Modal from "react-bootstrap/Modal";
import logoSIBUCSC from "../../assets/images/home/navbar/SIBUCSC.png";
import Button from "react-bootstrap/Button";
import { CWidgetStatsF, CLink } from "@coreui/react";
import { FaBullhorn } from "react-icons/fa";

const JournalDep = () => {
  const [showDep, setShowDep] = useState(false);
  const handleCloseDep = () => setShowDep(false);

  function handleShowDep() {
    setShowDep(true);
  }

  return (
    <>
      <Container>
        <Row className="row-lib pt-2 mt-5">
          <Col lg={12} md={12} xs={12}>
            <div className="cardCons mt-3">
              <div className="header">
                <span className="iconPlagio">
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clipRule="evenodd"
                      d="M18 3a1 1 0 00-1.447-.894L8.763 6H5a3 3 0 000 6h.28l1.771 5.316A1 1 0 008 18h1a1 1 0 001-1v-4.382l6.553 3.276A1 1 0 0018 15V3z"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                </span>
                <p className="titleInv"> ¿Qué es una revista Depredadora?</p>
              </div>
              <p className="infoP text-justify">
                Son revistas en las cuales no existen protocolos éticos y se
                auxilian para su estafa del engaño y operaciones poco
                transparentes. Constituyen un negocio fraudulento que persigue,
                atrae y, lastimosamente, captura a investigadores que no tienen
                mucha experiencia en producción científica, se aprovechan de su
                necesidad de publicar, derivada de la presión por los procesos
                evaluadores. Para ello, prometen procesos simples y rápidos de
                revisión y ventas a nivel mundial; muchas veces incluso atentan
                contra la calidad y la ética del artículo en cuestión.
              </p>
              <p className="infoP text-justify">
                Actualmente son catalogadas como una epidemia científica y
                consideradas como una expresión de corrupción, a nivel
                transnacional, en el ámbito académico. Publican todo lo que
                reciben, sin comprobar la calidad y legitimidad de los artículos
                y se desvían de las mejores prácticas editoriales y de
                publicación; ponen en tela de juicio su reputación y la calidad
                del trabajo científico. (Borroto Martínez et al., 2022).
              </p>
              <p className="infoP text-justify">
                Importante señalar que este término fue acuñado por Jeffrey
                Beall ( bibliotecólogo estadounidense)
              </p>
            </div>
          </Col>
          <Col lg={12} md={12} xs={12}>
            <div className="cardCons mt-3">
              <div className="header">
                <span className="iconPlagio">
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clipRule="evenodd"
                      d="M18 3a1 1 0 00-1.447-.894L8.763 6H5a3 3 0 000 6h.28l1.771 5.316A1 1 0 008 18h1a1 1 0 001-1v-4.382l6.553 3.276A1 1 0 0018 15V3z"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                </span>
                <p className="titleInv text-justify">
                  Consecuencias de publicar en revistas Depredadoras
                  (pseudo-journals)
                </p>
              </div>
              <p className="infoP text-justify">
                Un punto importante a considerar es que, si una revista es
                depredadora, los artículos publicados en ella carecen de validez
                académica. Claramente: no deberían ser citados, ni deberían ser
                utilizados ni para acreditaciones ni para ninguna clase
                de méritos que pueda sustentar una carrera académica.
              </p>
              <p className="infoP text-justify">
                Pero, tan importante como denunciarlas por el daño que hacen a
                la comunicación científica, es delimitar con precisión qué es y
                que no és una revista depredadora por las consecuencias injustas
                que una mala caracterización puede tener en las carreras
                académicas y en la propia comunicación de la ciencia. (Codina,
                Lluis, 2021)
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3">
            <CWidgetStatsF
              className="mb-3 font-bold"
              color="danger"
              footer={
                <CLink
                  className="font-weight-bold font-xs text-[#d12421]"
                  style={{ cursor: "pointer" }}
                  rel="noopener norefferer"
                  onClick={() => handleShowDep()}
                >
                  Ver información
                </CLink>
              }
              icon={<FaBullhorn height={24} />}
              title="¿Cómo reconocer una revista depredadora?"
            />
          </Col>
        </Row>
      </Container>
      <Modal show={showDep} size="xl" centered>
        <Modal.Header>
          <Modal.Title className="text-center">
            ¿Cómo reconocer una revista depredadora?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="invDev mt-1">
            <ul className="features">
              <li>
                <span className="icon">
                  <svg
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      fill="currentColor"
                      d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                    ></path>
                  </svg>
                </span>
                <span>
                  Si accedemos al sitio web de la revista, normalmente veremos
                  sitios de poca calidad, con errores ortográficos o de
                  gramática
                </span>
              </li>
              <li>
                <span className="icon">
                  <svg
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      fill="currentColor"
                      d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                    ></path>
                  </svg>
                </span>
                <span>
                  La revista no identifica dónde está ubicada su sede o la
                  ubicación es falsa. 
                </span>
              </li>
              <li>
                <span className="icon">
                  <svg
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      fill="currentColor"
                      d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                    ></path>
                  </svg>
                </span>
                <span>
                  No existe una institución u organización asociada a la
                  revista. 
                </span>
              </li>
              <li>
                <span className="icon">
                  <svg
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      fill="currentColor"
                      d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                    ></path>
                  </svg>
                </span>
                <span>Promesa de publicación rápida (horas o semanas)</span>
              </li>
              <li>
                <span className="icon">
                  <svg
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      fill="currentColor"
                      d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                    ></path>
                  </svg>
                </span>
                <span>
                  La revista abarca un amplio espectro de disciplinas para
                  atraer más artículos; por ejemplo, Journal of Medicine,
                  Journal of Sciences o African Journal of Case Reports.
                </span>
              </li>
              <li>
                <span className="icon">
                  <svg
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      fill="currentColor"
                      d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                    ></path>
                  </svg>
                </span>
                <span>
                  Utilizando tácticas agresivas de marketing y correo spam,
                  muchas veces, incluso, con faltas gramaticales o errores
                  ortográficos, solicitando que los investigadores envíen
                  artículos o se conviertan en editores de la revista.
                </span>
              </li>
              <li>
                <span className="icon">
                  <svg
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      fill="currentColor"
                      d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                    ></path>
                  </svg>
                </span>
                <span>
                  A menudo, los correos son dirigidos a investigadores jóvenes,
                  felicitándolos por sus publicaciones anteriores y la calidad
                  de su trabajo previo, con énfasis en la alta tasa de
                  aceptación de la revista. 
                </span>
              </li>
              <li>
                <span className="icon">
                  <svg
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      fill="currentColor"
                      d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                    ></path>
                  </svg>
                </span>
                <span>
                  Suelen contactar directamente con los autores por correo
                  electrónico, en lugar de mandar las llamadas a la colaboración
                  a través de foros o de listas de correos de autoridad
                  reconocida.
                </span>
              </li>
              <li>
                <span className="icon">
                  <svg
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      fill="currentColor"
                      d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                    ></path>
                  </svg>
                </span>
                <span>
                  Algunas solo poseen uno de los primeros números de la revista
                  y, a pesar de esto, afirman tener un alto índice de impacto.
                </span>
              </li>
              <li>
                <span className="icon">
                  <svg
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      fill="currentColor"
                      d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                    ></path>
                  </svg>
                </span>
                <span>
                  El correo electrónico de contacto pertenece a dominios como
                  gmail, yahoo, etc
                </span>
              </li>
              <li>
                <span className="icon">
                  <svg
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      fill="currentColor"
                      d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                    ></path>
                  </svg>
                </span>
                <span>
                  Mencionan índices de impacto o repertorios de revistas, de los
                  que supuestamente forman parte, pero que no corresponden con
                  las manejadas habitualmente por los académicos o las agencias
                  de evaluación, suelen ser índices ficticios o repertorios de
                  revistas falsos, si conviene creadas por las mismas revistas
                  depredadoras. Para ser incluida en una lista de indexación,
                  una revista debe cumplir con ciertos criterios, entre ellos:
                  frecuencia de publicación, poseer un comité editorial, que los
                  artículos publicados posean calidad y cumplan con los
                  estándares de la comunicación científica y que hayan pasado un
                  proceso de revisión por pares. Por ello, la indexación de una
                  revista en una base de datos es un indicador de calidad y
                  prestigio.
                </span>
              </li>
              <li>
                <span className="icon">
                  <svg
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      fill="currentColor"
                      d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                    ></path>
                  </svg>
                </span>
                <span>
                  Presentan usualmente ISSN (international standard serial
                  number) falsos o inexistentes, y en el caso de que posean uno
                  legítimo, lo utilizan como medio de vanagloria, lo cual
                  constituye una obviedad para toda publicación científica
                  reconocida.
                </span>
              </li>
              <li>
                <span className="icon">
                  <svg
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      fill="currentColor"
                      d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                    ></path>
                  </svg>
                </span>
                <span>
                  Puede haber indicios reveladores, por ejemplo, una cantidad
                  absurda de anuncios, o una verdadera dificultad si no
                  imposibilidad de encontrar un archivo con números anteriores. 
                </span>
              </li>
              <li>
                <span className="icon">
                  <svg
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      fill="currentColor"
                      d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                    ></path>
                  </svg>
                </span>
                <span>
                  Se camuflajean con nombres parecidos a otras revistas
                  conocidas internacionalmente, son habituales nombres como
                  International Journal of..., Global Review of... o Annals of
                  the World Association of
                </span>
              </li>
              <li>
                <span className="icon">
                  <svg
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      fill="currentColor"
                      d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                    ></path>
                  </svg>
                </span>
                <span>
                  Las revistas son exclusivamente digitales y cuentan con
                  dominios propios de internet. En general, desaparecen a los
                  pocos meses de ser creadas, tiempo suficiente para haber
                  capturado dinero de autores ingenuos o francamente
                  desesperados.
                </span>
              </li>
              <li>
                <span className="icon">
                  <svg
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      fill="currentColor"
                      d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                    ></path>
                  </svg>
                </span>
                <span>
                  Falsifican las métricas que presentan y suministran falsos
                  factores de impacto, llegan a utilizar algunos que no
                  corresponden a los habitualmente empleados. Estos datos
                  carecen de reconocimiento más allá de la revista que los
                  publica, ya que no se encuentran citadas en Web of Science.
                </span>
              </li>
              <li>
                <span className="icon">
                  <svg
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      fill="currentColor"
                      d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                    ></path>
                  </svg>
                </span>
                <span>
                  Carecen de políticas éticas o de publicación, o estas no están
                  claras, por lo que no controlan el plagio y muy pocas veces
                  recurren a la retractación de artículos publicados.
                </span>
              </li>
              <li>
                <span className="icon">
                  <svg
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      fill="currentColor"
                      d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                    ></path>
                  </svg>
                </span>
                <span>
                  Generalmente el editor copia (con pocos o ningún cambio) las
                  “normas para autores” de otros editores. No proporcionan datos
                  sobre sus editores o comités de editoriales, en muchos casos
                  los falsifican y en otros enlistan miembros del comité
                  editorial sin su permiso, o nombran miembros con cualidades
                  que no poseen en realidad.
                </span>
              </li>
              <li>
                <span className="icon">
                  <svg
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      fill="currentColor"
                      d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                    ></path>
                  </svg>
                </span>
                <span>
                  En la página principal de cada revista aparece un editor en
                  jefe, pero sin ninguna afiliación institucional, lo que induce
                  a pensar que no son reales. El editor también tiene una
                  propiedad web de marca diferenciada llamada IOSR Xplore, como
                  un intento de copiar el marketing del conocido sitio web de
                  IEEE Xplore.
                </span>
              </li>
              <li>
                <span className="icon">
                  <svg
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      fill="currentColor"
                      d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                    ></path>
                  </svg>
                </span>
                <span>
                  Estas publicaciones no siguen políticas de organizaciones como
                  WAME, el Comité de Publicaciones Éticas (COPE), el Comité
                  Internacional de Editores de Revistas Médicas (International
                  Committee of Medical Journals Editors, ICMJE) y el Consejo de
                  Editores de Ciencia (Council of Science Editors, CSE) respecto
                  a problemas como el archivo de materiales, el manejo de
                  potenciales conflictos de interés, de las erratas, y la
                  transparencia de los procesos y políticas, incluyendo las
                  cuota.
                </span>
              </li>
              <li>
                <span className="icon">
                  <svg
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      fill="currentColor"
                      d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                    ></path>
                  </svg>
                </span>
                <span>
                  Los costos de publicación son muy bajos ($100 - $200 USD) en
                  comparación con los de otras revistas de acceso abierto de
                  prestigio ($800 - $2000 USD) y, adicionalmente, ofertan
                  rebajas por segundo o tercer artículo.
                </span>
              </li>
              <li>
                <span className="icon">
                  <svg
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      fill="currentColor"
                      d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                    ></path>
                  </svg>
                </span>
                <span>
                  Las editoriales y revistas depredadoras carecen de la
                  financiación derivada de su comercialización, y su
                  supervivencia depende de nuevos modelos económicos como el
                  pago de tasas por el proceso de los artículos, pasan del
                  modelo habitual de cobro múltiple por lectura al de pago único
                  por publicación.
                </span>
              </li>
              <li>
                <span className="icon">
                  <svg
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      fill="currentColor"
                      d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                    ></path>
                  </svg>
                </span>
                <span>
                  La mayoría de los autores que en ellas publican tienden a ser
                  de países en vías de desarrollo o subdesarrollados, por lo
                  general (tres cuartas partes) de Asia y África. Resaltan entre
                  estos los estudiantes, investigadores jóvenes o con limitada
                  experiencia en la publicación de artículos.
                </span>
              </li>
              <li>
                <span className="icon">
                  <svg
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      fill="currentColor"
                      d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                    ></path>
                  </svg>
                </span>
                <span>
                  Generalmente son revistas jóvenes, de reciente creación, con
                  pocos números publicados. A pesar de esto, afirman tener un
                  alto índice de impacto. Suelen tener varias entregas al año,
                  con lo cual pretenden alentar la publicación inmediata. Debido
                  a que son exclusivamente digitales, desaparecen a los pocos
                  meses de ser creadas, tiempo suficiente para lucrar a costa de
                  ingenuos.
                </span>
              </li>
              <li>
                <span className="icon">
                  <svg
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      fill="currentColor"
                      d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                    ></path>
                  </svg>
                </span>
                <span>
                  Existen incoherencias relacionadas con su ubicación; al
                  respecto puede ocurrir que no se declara la sede, se declara
                  una sede falsa o que no coincida la sede identificada y la
                  real. De forma global, estas pueden dar la impresión de
                  encontrarse físicamente en países desarrollados como Estados
                  Unidos, Reino Unido, Australia o Canadá, y en ciudades con
                  prestigio por albergar universidades importantes o editoriales
                  exitosas. Pero la realidad es que la gran mayoría (más de dos
                  tercios) posee sus cuarteles en países subdesarrollados de
                  África o Asia como Pakistán, Turquía, Egipto, India, China o
                  Nigeria
                </span>
              </li>
            </ul>
            <p className="m-5">
              Fuente: Borroto Martínez, Karel, et al. (2022). Principales
              características de las revistas depredadoras. Revista Cubana de
              Información en Ciencias de la Salud, 33, e2171. Epub 30 de junio
              de 2022. Recuperado en 29 de febrero de 2024, de
              http://scielo.sld.cu/scielo.php?script=sci_arttext&pid=S2307-21132022000100013&lng=es&tlng=es.
            </p>
            <p className="m-5">
              Aquí puedes consultar la lista de revistas depredadoras de J.
              Beall :{" "}
              <a
                href="https://beallslist.weebly.com/"
                className="enlace"
                target="_blank"
              >
                https://beallslist.weebly.com/
              </a>
            </p>
            <br></br>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ float: "left" }}>
          <div className="w-100">
            <img src={logoSIBUCSC} className="h-10 bannerModal"></img>
            <Button variant="secondary float-right" onClick={handleCloseDep}>
              Cerrar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <br></br>
    </>
  );
};
export default JournalDep;

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "../../css/Investigation.css";

const JournalPub = () => {
  return (
    <>
      <Container>
        <Row className="row-lib pt-2 mt-5">
          <Col lg={12} md={12} xs={12}>
            <div className="cardCons mt-3">
              <div className="header">
                <span className="iconPlagio">
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clipRule="evenodd"
                      d="M18 3a1 1 0 00-1.447-.894L8.763 6H5a3 3 0 000 6h.28l1.771 5.316A1 1 0 008 18h1a1 1 0 001-1v-4.382l6.553 3.276A1 1 0 0018 15V3z"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                </span>
                <p className="titleInv">
                  ¿Cómo y dónde publicar mi artículo científico?
                </p>
              </div>
              <p className="infoP text-justify">
                Publicar es un paso fundamental en el proceso de investigación
                científica ya que nos permitirá comunicar los resultados de
                nuestra investigación y nuestra valoración como investigadores
                se hará a través de la evaluación de la calidad y repercusión de
                esta comunicación. Es, por tanto, fundamental saber dónde
                publicar.
              </p>
              <p className="infoP text-justify">
                Estos son los principales pasos a seguir:
              </p>
              <p className="infoP text-justify">
                <strong>1.- Elegir la revista:</strong> Realizar una búsqueda
                temática en bases de datos multidisciplinares de prestigio (Web
                of Science y Scopus) y bases de datos especializadas en nuestra
                materia.Conocer dónde publican nuestros autores de
                referencia.Conocer revistas mencionadas en foros especializados
                de nuestra área.Localizar la revista más adecuada para publicar
                a través de distintos recursos, como Journal Suggester, Journal
                Guide, Manuscript Matcher, Elsevier Journal Finder, Journal TOCS
                (sumarios), DOAJ, Dialnet, etc. Think-Check-Submit es otra
                herramienta útil que funciona como una lista de comprobación
                para evaluar las credenciales de una revista o editorial.
              </p>
              <p className="infoP text-justify">
                <strong>2.- Calidad de las publicaciones:</strong> Una vez
                preseleccionadas varias publicaciones, consultaremos sus
                indicios de calidad:
              </p>
              <p className="infoP text-justify">
                <strong>Factor de impacto, índice H y cuartil:</strong> JCR,
                SJR, Google Scholar Metrics.¿Tiene ISSN?¿Tiene una periodicidad
                clara y una antigüedad mínima de 2 años?. Podemos consultar
                Ulrichsweb para saberlo. Es recomendable consultar la propia web
                de la revista para:
              </p>
              <p className="infoP text-justify">
                <ul>
                  <li className="infoP text-justify">
                    - Conocer la composición del equipo editorial y el consejo
                    asesor de la revista. Es interesante que haya variedad de
                    afiliaciones institucionales, especialmente presencia
                    internacional.
                  </li>
                  <li className="infoP text-justify">
                    - Consultar la tasa de aceptación de la publicación (ratio
                    entre artículos recibidos y finalmente publicados); si es
                    baja, puede indicar alto nivel de exigencia de la revista.
                  </li>
                  <li className="infoP text-justify">
                    - Saber si la revista cuenta con un proceso de revisión por
                    pares (peer review).
                  </li>
                  <li>- Consultar las normas de publicación.</li>
                </ul>
              </p>
              <p className="infoP text-justify">
                <strong>Fuentes usadas:</strong> Consultar la sección “Aims and
                scope” de las revistas de nuestro interés para conocer si el
                objetivo y alcance de las mismas concuerdan con nuestra
                investigación.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <br></br>
    </>
  );
};
export default JournalPub;
